/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { useHistory } from "react-router-dom";
import CallDetails from "../../../../components/CallDetails";
import clsx from "clsx";
import { CallOverviewNew } from "../../../../models/Call";
import { useStyles } from "./CallItem.styles";
import { hasRole } from "../../../../utils";
import DeleteIcon from "@material-ui/icons/Delete";
import { useAuth0 } from "@auth0/auth0-react";

export interface CallItemProps {
  call: CallOverviewNew;
  deleteCall(id: string): void;
}

const CallItem: React.FC<CallItemProps> = ({ call, deleteCall }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const largeBreak = useMediaQuery(theme.breakpoints.down("lg"));
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { user } = useAuth0();

  const goToCall = (id: string) => {
    history.push(`/call/${id}`);
  };

  const handleDeleteCall = async (id) => {
    deleteCall(id);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.column1}
        onClick={() => {
          call?.job_status === "complete" && goToCall(call.id);
        }}
      >
        {
          <CallDetails
            summary={{
              authenticated: true,
              uploaded_filename: call.uploaded_filename,
              risk_score: call.risk_score,
              duration: call.duration,
            }}
          />
        }
      </div>
      <>
        {largeBreak ? (
          <div>
            <Divider variant="middle" />
          </div>
        ) : null}
      </>
      <div className={classes.column2}>
        {call.job_status === "pending" && <Typography variant="h6">Analysing</Typography>}
        {call.job_status === "complete" && (
          <>
            <div className={classes.callClassifications}>
              <Chip
                variant="outlined"
                size="small"
                label="Complaint"
                className={clsx(classes.riskButton, call.summary?.complaint && classes.activeRisk)}
              />
              <Chip
                variant="outlined"
                size="small"
                label="Vulnerability"
                className={clsx(classes.riskButton, call.summary?.vulnerability && classes.activeRisk)}
              />
              <Chip
                variant="outlined"
                size="small"
                label="Conduct"
                className={clsx(classes.riskButton, call.summary?.conduct && classes.activeRisk)}
              />
              <Chip
                variant="outlined"
                size="small"
                label="Watchlist"
                className={clsx(classes.riskButton, call.summary?.watchlist && classes.activeRisk)}
              />
              <div className={classes.deleteIcon}>
                {hasRole(user, "TenantAdmin") && (
                  <>
                    <IconButton onClick={handleOpen}>
                      <DeleteIcon />
                    </IconButton>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">{"DELETE THIS CALL"}</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure you want to delete this call?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => handleDeleteCall(call.id)} color="primary">
                          Yes
                        </Button>
                        <Button onClick={handleClose} color="primary" autoFocus>
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { CallItem };
