import { Filter, FilterGroup } from "./index";

const riskLevelFilters: Filter[] = [
  { value: "0", label: "0", checked: false },
  { value: "1", label: "1", checked: false },
  { value: "2", label: "2", checked: false },
  { value: "3", label: "3", checked: false },
  { value: "4", label: "4", checked: false },
  { value: "5", label: "5", checked: false },
];

export const riskLevelGroup: FilterGroup = {
  title: "Risk Level",
  filters: riskLevelFilters,
  group: "riskLevel",
};
