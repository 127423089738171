import { Action, action } from "easy-peasy";
import { Filters, filters } from "./filters";
import { Sort, defaultSort } from "./Sort";

export type CallStatus = "pending" | "complete" | "unassessable";
export interface CallOwnerFilter {
  checked: boolean;
  label: string;
  value: string;
}

export interface StoreModel {
  tempFilterGroups: Filters;
  setTempFilterGroups: Action<StoreModel, Filters>;
  filterGroups: Filters;
  setFilterGroups: Action<StoreModel, Filters>;
  selectedTime: number | null;
  setSelectedTime: Action<StoreModel, number | null>;
  currentAudioTime: number;
  setCurrentAudioTime: Action<StoreModel, number>;
  playbackRate: number;
  setPlaybackRate: Action<StoreModel, number>;
  followTranscript: boolean;
  setFollowTranscript: Action<StoreModel, boolean>;
  searchTerm: string | null;
  setsearchTerm: Action<StoreModel, string | null>;
  sort: Sort;
  setSort: Action<StoreModel, Sort>;
  doFilter: boolean;
  setDoFilter: Action<StoreModel, boolean>;
  callStatus: CallStatus;
  setCallStatus: Action<StoreModel, CallStatus>;
  adminStartDate: Date | null;
  setAdminStartDate: Action<StoreModel, Date>;
  adminEndDate: Date | null;
  setAdminEndDate: Action<StoreModel, Date>;
}

export const storeModel: StoreModel = {
  tempFilterGroups: filters,
  setTempFilterGroups: action((state, payload) => {
    state.tempFilterGroups = payload;
  }),
  filterGroups: filters,
  setFilterGroups: action((state, payload) => {
    state.filterGroups = payload;
  }),
  selectedTime: 0,
  setSelectedTime: action((state, payload) => {
    state.selectedTime = payload;
  }),
  currentAudioTime: 0,
  setCurrentAudioTime: action((state, payload) => {
    state.currentAudioTime = payload;
  }),
  playbackRate: 1.2,
  setPlaybackRate: action((state, payload) => {
    state.playbackRate = payload;
  }),
  followTranscript: true,
  setFollowTranscript: action((state, payload) => {
    state.followTranscript = payload;
  }),
  searchTerm: null,
  setsearchTerm: action((state, payload) => {
    state.searchTerm = payload;
  }),
  sort: defaultSort,
  setSort: action((state, payload) => {
    state.sort = payload;
  }),
  doFilter: false,
  setDoFilter: action((state, payload) => {
    state.doFilter = payload;
  }),
  callStatus: "pending",
  setCallStatus: action((state, payload) => {
    state.callStatus = payload;
  }),
  adminStartDate: null,
  setAdminStartDate: action((state, payload) => {
    state.adminStartDate = payload;
  }),
  adminEndDate: null,
  setAdminEndDate: action((state, payload) => {
    state.adminEndDate = payload;
  }),
};
