import { createMuiTheme } from "@material-ui/core/styles";
import overrides from "./overrides";
import { paletteLight as palette } from "./palette";
import typography from "./typography";

const lightTheme = createMuiTheme({
  palette,
  typography,
  overrides,
});

export { lightTheme };
