import { durationGroup } from "./duration";
import { riskLevelGroup } from "./riskLevel";
import { riskTypeGroup } from "./riskType";
// import { statusGroup } from "./status";
import { preCheckGroup } from "./authenticated";
import { callOwnerGroup } from "./callOwner";

export interface Filters {
  duration: FilterGroup;
  riskLevel: FilterGroup;
  preCheck: FilterGroup;
  riskType: FilterGroup;
  // status: FilterGroup;
  callOwner: FilterGroup;
}

export const filters: Filters = {
  duration: durationGroup,
  riskLevel: riskLevelGroup,
  preCheck: preCheckGroup,
  riskType: riskTypeGroup,
  // status: statusGroup,
  callOwner: callOwnerGroup,
};

export interface Filter {
  value: string;
  label: string;
  checked: boolean;
}

export interface FilterGroup {
  title: string;
  filters: Filter[];
  group: string;
}
