import React, { useState, Dispatch, SetStateAction } from "react";
import { createStyles, Theme, makeStyles, useTheme } from "@material-ui/core/styles";
import { AppBar, Button, Toolbar } from "@material-ui/core";
import clsx from "clsx";
import TabFilters from "./TabFilters";
import { useStoreActions, useStoreState } from "./../../../../hooks";
import { paletteLight } from "./../../../../newTheme/palette";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      marginTop: "64px",
      [theme.breakpoints.down("md")]: {
        marginTop: "0px",
      },
      color: paletteLight.white.main,
      height: "calc(100% - 128px)",
      position: "relative",
    },
    toolbar: {
      // minHeight: "60px",
      borderRadius: "2px 0 0 0",
      backgroundColor: paletteLight.darkBlue.light,
      display: "flex",
      justifyContent: "center",
      "&.button": {
        width: "100%",
      },
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(2),
        // margin: theme.spacing(2),
      },
    },
    filters: {
      padding: theme.spacing(2),
      borderRadius: "0 0 0 2px",
      background: "rgba(79,161,220, 0.9)",
      overflow: "auto",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    filtersButton: {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        width: "80%",
      },
      color: paletteLight.white.main,
      "&.selected": {
        backgroundColor: paletteLight.lightBlue.main,
      },
    },
    appBar: {
      backgroundColor: paletteLight.darkBlue.main,
      position: "absolute",
      top: "auto",
      bottom: 0,
      boxShadow: "none",
    },
    bottomToolbar: {
      display: "flex",
    },
    bottoToolbarButton: {
      margin: theme.spacing(1),
      width: "100%",
      border: `1px solid ${paletteLight.white.main}`,
      color: paletteLight.white.main,
    },
    btnApply: {
      backgroundColor: paletteLight.white.main,
      color: paletteLight.darkBlue.main,
    },
    lockIcon: {
      fill: paletteLight.white.main,
    },
    placeholder: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }),
);

interface FilterProps {
  isDrawerOpen?: boolean;
  setDrawerOpen?: Dispatch<SetStateAction<boolean>>;
}

const Filters: React.FC<FilterProps> = ({ isDrawerOpen, setDrawerOpen }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [mode] = useState("filters");
  const tempFilters = useStoreState((state) => state.tempFilterGroups);
  const setTempFilters = useStoreActions((actions) => actions.setTempFilterGroups);
  const doFilter = useStoreState((state) => state.doFilter);
  const setDoFilter = useStoreActions((actions) => actions.setDoFilter);

  const handleClear = () => {
    const newFilters = { ...tempFilters };
    const groups = Object.keys(newFilters);
    groups.forEach((group) => {
      if (group === "status") return;
      newFilters[group].filters.forEach((filter) => (filter.checked = false));
    });
    setTempFilters(newFilters);
  };

  const handleFilter = () => {
    setDoFilter(!doFilter);
    if (isDrawerOpen) {
      setDrawerOpen(false);
    }
  };

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Button
          variant={mode === "filters" ? "contained" : "text"}
          className={clsx(classes.filtersButton, mode === "filters" && "selected")}
          data-cy="FilterTabButton"
        >
          Filters
        </Button>
      </Toolbar>
      <div className={classes.filters}>
        <div>
          <TabFilters />
          <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar className={classes.bottomToolbar}>
              <Button variant="outlined" className={classes.bottoToolbarButton} onClick={() => handleClear()}>
                Clear
              </Button>
              <Button
                variant="contained"
                className={clsx(classes.bottoToolbarButton, classes.btnApply)}
                onClick={() => handleFilter()}
              >
                Apply Changes
              </Button>
            </Toolbar>
          </AppBar>
        </div>
      </div>
    </div>
  );
};

export { Filters };
