import { makeStyles } from "@material-ui/core";
import { paletteLight } from "../../../../newTheme/palette";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: "14px",
    display: "grid",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "auto",
      gridTemplateRows: "1fr 1fr 1fr",
    },
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    backgroundColor: "#fff",
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    borderRadius: `2px`,
    boxShadow: "0px 0px 3px 1px rgba(89,114,184,.4)",
    color: "#000",
    cursor: "pointer",
  },
  column1: {
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
  },
  column2: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      marginLeft: `${theme.spacing(3)}px`,
    },
  },
  authenticatedIcon: {},
  authenticated: {},
  riskButton: {
    padding: `${theme.spacing(2)}px`,
    margin: `0px ${theme.spacing(1)}px`,
    fontSize: "1em",
    fontFamily: '"Roboto", sans-serif',
    textTransform: "uppercase",
  },
  activeRisk: {
    backgroundColor: theme.palette.lightBlue.main,
    border: `1px solid ${theme.palette.lightBlue.main}`,
    color: theme.palette.white.main,
  },
  deleteIcon: {
    display: "flex",
    fontSize: "16px",
    [theme.breakpoints.down("lg")]: {
      justifySelf: "flex-end",
      marginRight: `${theme.spacing(3)}px`,
    },
  },
  callClassifications: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 0.5fr",
    alignContent: "center",
  },
  many: {
    fill: paletteLight.error.main,
    alignSelf: "center",
  },
  some: {
    fill: paletteLight.info.main,
    alignSelf: "center",
  },
  none: {
    fill: paletteLight.success.main,
    alignSelf: "center",
  },
}));
