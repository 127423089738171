/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useMemo } from "react";
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";
// import { Utterance } from "./../../../models/Call";
import { Typography } from "@material-ui/core";
import { Sparkline } from "./../Panels/Utils/Sparkline";
import { PaceQuality } from "../../../models/Call";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(0),
  },
  table: {
    width: "100%",
    textAlign: "center",
    borderCollapse: "separate",
    borderSpacing: "4px",
    "& > thead": {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  },
  row: {
    maxHeight: "1em",
    "& > td": {
      height: "80px",
      minWidth: "100px",
    },
  },
  label: {
    width: "120px",
    flexGrow: theme.spacing(0),
  },
  chart: {
    maxHeight: "48px",
    width: "200px",
    overflow: "hidden",
  },
}));

export interface DashboardProps {
  utterances: any;
  overlap: any;
  avg: PaceQuality;
}

const dataPointsToShow = 50;

const Dashboard: React.FunctionComponent<DashboardProps> = ({ utterances, overlap, avg }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [minMax, setMinMax] = useState({ min: 0, max: 0 });
  const [totalTalkTime, setTotalTalkTime] = useState(0);

  useEffect(() => {
    if (!utterances) return;
    const sortedConfidence = utterances
      .reduce((acc, cv) => {
        acc.push(parseFloat(cv.confidence));
        return acc;
      }, [])
      .sort();

    setMinMax({
      min: sortedConfidence[0] - 0.3,
      max: sortedConfidence[sortedConfidence.length - 1] + 0.3,
    });
  }, [utterances]);

  useEffect(() => {
    if (!utterances) return;
    const talkTime = utterances.reduce((acc, cv) => {
      return acc + (parseFloat(cv.endtime) - parseFloat(cv.starttime));
    }, 0);
    setTotalTalkTime(talkTime);
  }, [setTotalTalkTime, utterances]);

  const agentUtterances = useMemo(() => utterances?.filter((u) => u.channel === "ch_0"), [utterances]);
  const memberUtterances = useMemo(() => utterances?.filter((u) => u.channel === "ch_1"), [utterances]);

  const agentUtterancesGraph = useMemo(() => {
    if (!agentUtterances) {
      return [];
    }
    const rowCount = agentUtterances.length;
    if (rowCount < dataPointsToShow) {
      return agentUtterances;
    }
    const nth = Math.floor(rowCount / dataPointsToShow);
    return agentUtterances.filter((r, idx) => idx % nth === 0);
  }, [agentUtterances]);
  const memberUtterancesGraph = useMemo(() => {
    if (!memberUtterances) {
      return [];
    }
    const rowCount = memberUtterances.length;
    if (rowCount < dataPointsToShow) {
      return memberUtterances;
    }
    const nth = Math.floor(rowCount / dataPointsToShow);
    return memberUtterances.filter((r, idx) => idx % nth === 0);
  }, [memberUtterances]);

  const agentPace = useMemo(() => agentUtterancesGraph.map((u) => u.wpm), [agentUtterancesGraph]);
  const memberPace = useMemo(() => memberUtterancesGraph.map((u) => u.wpm), [memberUtterancesGraph]);

  const agentClarity = useMemo(() => agentUtterancesGraph.map((u) => parseFloat(u.confidence)), [agentUtterancesGraph]);
  const memberClarity = useMemo(() => memberUtterancesGraph.map((u) => parseFloat(u.confidence)), [
    memberUtterancesGraph,
  ]);

  return (
    <div className={classes.root}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>
              <Typography variant="h5">Speaker</Typography>
            </th>
            <th>
              <Typography variant="h5">Talk Time</Typography>
            </th>
            <th>
              <Typography variant="h5">Pace</Typography>
            </th>
            <th>
              <Typography variant="h5">Clarity</Typography>
            </th>
            <th>
              <Typography variant="h5">Interruptions</Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Typography variant="h5">Agent</Typography>
            </td>
            <td>
              <Typography variant="h5">
                {Math.round(
                  (agentUtterances.reduce((acc, cv) => acc + (parseFloat(cv.endtime) - parseFloat(cv.starttime)), 0) /
                    totalTalkTime) *
                    100,
                )}
                %
              </Typography>
            </td>
            <td className={classes.chart}>
              <Sparkline type="line" data={agentPace} height="48px" person="agent" avg={avg?.avg_pace_agent} />
            </td>
            <td className={classes.chart}>
              <Sparkline
                minMax={minMax}
                type="line"
                data={agentClarity}
                height="48px"
                person="agent"
                avg={avg?.avg_clarity_agent}
              />
            </td>
            <td>
              <Typography variant="h5">{overlap?.filter((o) => o.interrupter === "ch_0").length}</Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="h5">Member</Typography>
            </td>
            <td>
              <Typography variant="h5">
                {Math.round(
                  (memberUtterances.reduce((acc, cv) => acc + (parseFloat(cv.endtime) - parseFloat(cv.starttime)), 0) /
                    totalTalkTime) *
                    100,
                )}
                %
              </Typography>
            </td>
            <td className={classes.chart}>
              <Sparkline type="line" data={memberPace} height="48px" person="member" avg={avg?.avg_pace_member} />
            </td>
            <td className={classes.chart}>
              <Sparkline
                minMax={minMax}
                type="line"
                data={memberClarity}
                height="48px"
                person="member"
                avg={avg?.avg_clarity_member}
              />
            </td>
            <td>
              <Typography variant="h5">{overlap?.filter((o) => o.interrupter === "ch_1").length}</Typography>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export { Dashboard };
export default Dashboard;
