import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  ListItem,
  ListItemText,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { Overlap } from "../../../../models/Overlap";
import { formatTime } from "../Transcript/Transcript";
import clsx from "clsx";
import { paletteLight } from "../../../../newTheme/palette";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    // minWidth: 275,
    display: "flex",
    flexDirection: "column",
    boxShadow: "none !important",
    height: "100%",
  },
  title: {
    padding: theme.spacing(1),
  },
  content: {
    backgroundColor: paletteLight.background.light,
    padding: theme.spacing(1),
  },
  timeStamp: {
    textAlign: "right",
    marginBottom: theme.spacing(0),
  },
  cardWrapper: {
    flexGrow: 1,
    overflow: "auto",
    "& :first-child": {
      paddingTop: theme.spacing(1),
    },
  },
  triggerUtterance1: {
    color: paletteLight.secondary.main,
    backgroundColor: paletteLight.background.main,
    "&:hover": {
      backgroundColor: paletteLight.background.dark,
    },
    marginBottom: theme.spacing(1),
  },
  triggerUtterance2: {
    color: paletteLight.secondary.main,
    backgroundColor: paletteLight.background.main,
    "&:hover": {
      backgroundColor: paletteLight.background.dark,
    },
    marginBottom: theme.spacing(1),
  },
  adviser: {
    backgroundColor: paletteLight.primary.main,
  },
  client: {
    backgroundColor: paletteLight.info.main,
  },
  overlapCard: {
    margin: theme.spacing(1),
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export interface OverlapCardProps {
  overlap: Overlap;
  onSelected: (time: number) => void;
}

export const OverlapCard: React.FunctionComponent<OverlapCardProps> = ({ overlap, onSelected }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Card key={overlap.id} className={classes.overlapCard}>
      <CardHeader
        avatar={
          overlap.interrupter === "ch_1" ? (
            <Avatar aria-label="recipe" className={classes.adviser}>
              A
            </Avatar>
          ) : (
            <Avatar aria-label="recipe" className={classes.client}>
              M
            </Avatar>
          )
        }
        onClick={() => setExpanded(!expanded)}
        action={
          <IconButton
            aria-label="show more"
            aria-expanded={expanded}
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
          >
            <ExpandMore />
          </IconButton>
        }
        title={`${overlap.interrupter === "ch_1" ? "Adviser" : "Member"} interrupted ${
          overlap.interrupter === "ch_1" ? "Member" : "Adviser"
        } by ${overlap.overlap} secs`}
        subheader={`At ${formatTime(overlap.starttime)} into the call`}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <ListItem
            button
            onClick={(ev) => {
              ev.stopPropagation();
              onSelected(parseFloat(overlap.starttime));
            }}
            className={classes.triggerUtterance1}
          >
            <ListItemText primary={overlap.u1} />
          </ListItem>
          <ListItem
            button
            onClick={(ev) => {
              ev.stopPropagation();
              onSelected(parseFloat(overlap.starttime));
            }}
            className={classes.triggerUtterance2}
          >
            <ListItemText primary={overlap.u2} />
          </ListItem>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default OverlapCard;
