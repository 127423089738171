import React, { useCallback, useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import { MenuItem, IconButton, Menu, Fab, Checkbox, CircularProgress } from "@material-ui/core";
import { useStoreActions, useStoreState } from "./../../../hooks";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useQuery } from "react-query";
import { getCallAudio } from "./../../../services/api";
import { useAuth0 } from "@auth0/auth0-react";
import { FastForward, FastRewind, Pause, PlayArrow } from "@material-ui/icons";
import { paletteLight } from "./../../../newTheme/palette";

const useStyles = makeStyles((theme: Theme) => ({
  audioPlayer: {
    display: "none",
  },
  audioPlayerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(0),
    marginBottom: "0",
  },
  buttonContainer: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  playbackButton: {
    margin: theme.spacing(0.5),
    boxShadow: "none",
    backgroundColor: paletteLight.info.main,
    "&:hover": {
      backgroundColor: paletteLight.secondary.main,
    },
    "& > span": {
      color: "#fff",
      "&:hover": {
        color: "#fff",
      },
    },
  },
  audioTime: {
    width: "48px",
    textAlign: "center",
    color: paletteLight.secondary.main,
  },

  followAlongCheckbox: {
    padding: `0 ${theme.spacing(0.5)}px 0 0`,
  },
}));

interface Rate {
  label: string;
  rate: number;
}

const rates: Rate[] = [
  { label: "Slow", rate: 0.7 },
  { label: "Normal", rate: 1 },
  { label: "Fast", rate: 1.2 },
  { label: "Ludicrous", rate: 1.5 },
];

const nullCallback = () => {};

export interface AudioPlayerProps {
  filename: string;
  audioUrl?: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ filename }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const setPlaybackRate = useStoreActions((actions) => actions.setPlaybackRate);
  const setCurrentAudioTime = useStoreActions((actions) => actions.setCurrentAudioTime);
  const [currentLocalAudioTime, setCurrentLocalAudioTime] = useState(0);
  const playbackRate = useStoreState((state) => state.playbackRate);
  const selectedTime = useStoreState((state) => state.selectedTime);
  const setSelectedTime = useStoreActions((actions) => actions.setSelectedTime);
  const followTranscript = useStoreState((state) => state.followTranscript);
  const setFollowTranscript = useStoreActions((actions) => actions.setFollowTranscript);
  const [playing, setPlaying] = useState(false);
  const { getIdTokenClaims } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [audioEl, setAudioEl] = useState<null | HTMLAudioElement>(null);

  const { isLoading, error, data: audioUrl }: { isLoading: boolean; error: unknown; data: string } = useQuery(
    ["audioUrl", filename],
    async () => {
      const claims = await getIdTokenClaims();
      const token = claims["__raw"];
      return await getCallAudio(filename, token);
    },
  );

  useEffect(() => {
    if (audioEl) {
      audioEl.playbackRate = playbackRate;
    }
  }, [playbackRate, audioEl]);

  useEffect(() => {
    if (selectedTime === null || !audioEl) {
      return;
    }
    audioEl.currentTime = selectedTime;
    setSelectedTime(null);
  }, [selectedTime, setSelectedTime, audioEl]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(
    (val) => {
      if (!isNaN(val)) {
        setPlaybackRate(val);
      }
      setAnchorEl(null);
    },
    [setPlaybackRate],
  );

  const handleTranscriptFollow = useCallback(() => {
    setFollowTranscript(!followTranscript);
  }, [followTranscript, setFollowTranscript]);

  const playPause = useCallback(() => {
    if (!audioEl) {
      return;
    }

    const audio = audioEl;

    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [audioEl]);

  const seek = useCallback(
    (direction: "back" | "forward") => {
      if (!audioEl) {
        return;
      }
      let time = 10;
      if (direction === "back") {
        time = -time;
      }
      audioEl.currentTime = audioEl.currentTime + time;
    },
    [audioEl],
  );

  const currentTimeUpdate = useCallback(
    (event: React.SyntheticEvent<HTMLAudioElement, Event>) => {
      setCurrentLocalAudioTime(event.currentTarget.currentTime);
      setCurrentAudioTime(event.currentTarget.currentTime);
    },
    [setCurrentAudioTime],
  );

  const handlePlay = useCallback(() => setPlaying(true), []);
  const handlePause = useCallback(() => setPlaying(false), []);
  const updateRef = useCallback((ref: HTMLAudioElement) => setAudioEl(ref), []);

  const seekBack = useCallback(() => seek("back"), [seek]);
  const seekForward = useCallback(() => seek("forward"), [seek]);

  const playbackRates = useMemo(
    () =>
      rates.map((r: Rate) => (
        <MenuItem key={r.label} onClick={() => handleClose(r.rate)}>
          {r.label}{" "}
          <FiberManualRecordIcon
            color="secondary"
            style={{
              marginLeft: theme.spacing(1),
              fontSize: "8px",
              opacity: playbackRate === r.rate ? 1 : 0.1,
            }}
          />
        </MenuItem>
      )),
    [playbackRate, handleClose],
  );

  if (error) return <div>Error loading audio</div>;
  if (isLoading)
    return (
      <div>
        <CircularProgress color="primary" />
      </div>
    );
  return (
    <>
      <div className={clsx(classes.audioPlayerContainer)}>
        {audioUrl && (
          <audio
            controls
            controlsList="nodownload"
            className={classes.audioPlayer}
            ref={updateRef}
            onPlay={handlePlay}
            onPause={handlePause}
            onTimeUpdate={currentTimeUpdate}
          >
            <source src={audioUrl} type="audio/mpeg" />
          </audio>
        )}

        <div>
          <Fab size="small" className={classes.playbackButton} onClick={seekBack}>
            <FastRewind />
          </Fab>
          <Fab size="medium" className={classes.playbackButton} onClick={playPause}>
            {!playing ? <PlayArrow /> : <Pause />}
          </Fab>
          <Fab size="small" className={classes.playbackButton} onClick={seekForward}>
            <FastForward />
          </Fab>
        </div>

        <div className={classes.audioTime}>{formatCallTimer(currentLocalAudioTime)}</div>
      </div>

      <div className={classes.buttonContainer}>
        <div>
          <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </div>
        <div>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleTranscriptFollow}>
              <Checkbox className={classes.followAlongCheckbox} checked={followTranscript} onChange={nullCallback} />
              Follow Transcript
            </MenuItem>
            <MenuItem onClick={handleClose} disabled={true}>
              Playback speed
            </MenuItem>
            {playbackRates}
          </Menu>
        </div>
      </div>
    </>
  );
};

export const formatCallTimer = (duration: number): string => {
  const seconds = Math.round(duration % 60);
  let minutes = Math.floor(duration / 60);
  let hours = 0;
  if (minutes >= 60) {
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
  }
  if (hours) {
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export default AudioPlayer;
export { AudioPlayer };
