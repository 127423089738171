import React from "react";

export interface RiskTabPanelProps {
  children?: React.ReactNode;
  className?: string;
  index: number;
  value: number;
  padding?: number;
}

export const RiskTabPanel = (props: RiskTabPanelProps) => {
  const { children, className, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      className={className}
    >
      {value === index && <div style={{ padding: 0 }}>{children}</div>}
    </div>
  );
};

export default RiskTabPanel;
