/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Avatar, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { getTenantName, hasRole } from "./../utils";
import { useHistory } from "react-router-dom";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import InfoIcon from "@material-ui/icons/Info";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 3,
      justifyContent: "space-between",
    },
    buttonContainer: {
      flexGrow: 0,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    profileMenuHeader: {
      alignItems: "center",
      width: "200px",
      paddingBottom: theme.spacing(1),
    },
    largeAvatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    listItemIcon: {
      minWidth: "32px",
    },
    tenantName: {
      backgroundColor: "#60AADF !important",
      color: "white",
      fontSize: "1.4rem",
      top: "-8px",
    },
  }),
);

const ProfileButton = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { user, logout } = useAuth0();
  const history = useHistory();
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.buttonContainer}>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        data-cy="ProfileButton"
      >
        <Avatar alt={user.nickname} src={user.picture} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        data-cy="ProfileMenu"
      >
        <MenuItem className={classes.tenantName}>
          <Typography variant="h6" style={{ width: "100%" }} align="center">
            {getTenantName(user)}
          </Typography>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <Grid container className={classes.profileMenuHeader} spacing={4}>
            <Grid item xs={4}>
              <Avatar alt={user.name} src={user.picture} className={classes.largeAvatar} />
            </Grid>
            <Grid item xs={8}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{user.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">{user.email}</Typography>
              </Grid>
              {!user.email_verified && (
                <Grid item xs={12}>
                  <Typography variant="caption">Unverified</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </MenuItem>
        <Divider />

        {hasRole(user, "TenantAdmin") && (
          <MenuItem onClick={() => history.push("/admin")}>
            <ListItemIcon className={classes.listItemIcon}>
              <SupervisorAccountIcon fontSize="small" />
            </ListItemIcon>
            Admin
          </MenuItem>
        )}
        <Divider />
        <MenuItem>
          <ListItemIcon className={classes.listItemIcon}>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          App version: {process.env.REACT_APP_COMMIT_SHA}
        </MenuItem>

        <Divider />
        <MenuItem onClick={() => logout({ returnTo: window.location.origin })} data-cy="LogoutButton">
          <ListItemIcon className={classes.listItemIcon}>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export { ProfileButton };
export default ProfileButton;
