import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { paletteLight } from "./../../newTheme/palette";

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      backgroundColor: "white !important",
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
    },
    errorMessContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      color: paletteLight.primary.main,
      margin: theme.spacing(2),
    },
    subtitle1: {
      color: paletteLight.secondary.main,
      lineHeight: "120%",
      margin: theme.spacing(2),
    },
    errorLogo: {
      height: "300px",
      width: "auto",
    },
    headerTextContainer: {
      textAlign: "center",
      marginBottom: theme.spacing(8),
      fontFamily: "poppins",
    },
    refreshButton: {
      width: "15%",
      border: "1px solid",
      borderColor: paletteLight.primary.main,
      color: paletteLight.primary.main,
      borderRadius: "30px",
      fontFamily: "poppins",
      fontWeight: "bold",
      padding: theme.spacing(2),
      "&:hover": {
        color: "#fff",
        backgroundColor: paletteLight.primary.main,
        cursor: "pointer",
      },
      margin: "5px",
    },
    buttons: {
      width: "75%",
      display: "flex",
      justifyContent: "center",
    },
  });
});
