/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core/";
import { paletteLight } from "../../../../newTheme/palette";

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      minWidth: 72,
      fontSize: "20px",
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: '"Arial", sans-serif',
      "&:hover": {
        color: "#000",
      },
      "&$selected": {
        color: "#000",
      },
      "&:focus": {
        color: "#000",
      },
    },
  }),
)(Tab);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  tabContainer: {
    backgroundColor: paletteLight.background.light,
  },
  label: {
    color: "#000",
    padding: theme.spacing(3),
  },
  indicator: {
    backgroundColor: paletteLight.primary.main,
  },
}));

export interface RiskBarProps {
  activeTab: number;
  setActiveTab: (tab: number) => void;
}

const risksTemplate = ["Risks", "Interruptions"];

const RiskBar: React.FunctionComponent<RiskBarProps> = ({ activeTab, setActiveTab }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.tabContainer}>
        <AntTabs
          value={activeTab}
          onChange={(_, val) => setActiveTab(val)}
          variant="fullWidth"
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
          }}
          aria-label="ant example"
        >
          {risksTemplate.map((risk) => {
            return <AntTab key={risk} label={risk} />;
          })}
        </AntTabs>
      </div>
    </div>
  );
};

export { RiskBar };
export default RiskBar;
