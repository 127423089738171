/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, RefObject, useMemo, useCallback } from "react";
import ChatMsg from "./ChatMsg";
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { Utterance } from "./../../../../models/Call";
// import LabelDialog from "./LabelDialog";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import SearchBar from "material-ui-search-bar";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "0",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  search: {
    flexGrow: 0,
    marginBottom: theme.spacing(1),
  },
  chatMsg: {
    cursor: "pointer",
    "& > .icon": {
      opacity: "10%",
    },
    "&:hover": {
      "& > .icon": {
        opacity: "100%",
      },
    },
  },
  chatContainer: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto",
  },
  transcriptionEl: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export interface TranscriptProps {
  transcript: any;
}

const Transcript: React.FunctionComponent<TranscriptProps> = ({ transcript }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [chat, setChat] = useState<Utterance[]>(null);
  const currentAudioTime = useStoreState((state) => state.currentAudioTime);
  const setSelectedTime = useStoreActions((actions) => actions.setSelectedTime);
  const followTranscript = useStoreState((state) => state.followTranscript);
  const chatContainer = useRef<HTMLDivElement>(null);
  const [refs, setRefs] = useState<{ [key: number]: RefObject<HTMLDivElement> }>({});
  // const [labelDialogOpen, setLabelDialogOpen] = useState(false);
  // const [currentUtterance, setCurrentUtterance] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!chat) return;
    setRefs(
      chat.reduce((acc, value) => {
        acc[value.starttime] = React.createRef();
        return acc;
      }, {}),
    );
  }, [chat]);

  useEffect(() => {
    setChat(transcript);
  }, [transcript]);

  useEffect(() => {
    if (!followTranscript) {
      return;
    }
    const bubbles = Object.values(refs).map((i) => i.current);
    if (!bubbles.length) {
      return;
    }
    let current: HTMLElement | undefined;
    let useNext = false;
    for (const bubble of bubbles) {
      if (useNext) {
        current = bubble;
        break;
      }
      if (!bubble?.dataset) {
        continue;
      }
      const start = parseInt(bubble.dataset.startTime);
      const end = parseInt(bubble.dataset.endTime);
      if (currentAudioTime >= start && currentAudioTime <= end) {
        current = bubble;
        useNext = true;
      }
    }
    if (current) {
      current.scrollIntoView({ block: "end" });
    }
  }, [currentAudioTime, followTranscript]);

  const doSearch = (searchTerm, transcript) => {
    const found = transcript.find((u) => {
      return u.utterance.toLowerCase().includes(searchTerm.toLowerCase());
    });
    if (!found) return;
    setSelectedTime(parseInt(found.starttime));
  };

  const utteranceClicked = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const time = parseFloat(event.currentTarget.dataset.startTime);
      setSelectedTime(time);
    },
    [setSelectedTime],
  );

  const activeUtterances = useMemo(() => {
    const active = transcript
      .filter((u) => currentAudioTime >= parseFloat(u.starttime) && currentAudioTime <= parseFloat(u.endtime))
      .map((u) => u.utterance_id);
    return active.join(",");
  }, [transcript, currentAudioTime]);

  const msgs = useMemo(() => {
    if (!transcript) {
      return [];
    }

    const active = activeUtterances.split(",");

    return transcript?.map((c) => (
      <div
        key={c.utterance_id}
        className={classes.chatMsg}
        data-start-time={c.starttime}
        data-end-time={c.endtime}
        ref={refs[c.starttime]}
        onClick={utteranceClicked}
      >
        <div aria-label="chat message with click">
          <ChatMsg
            // onAddLabelClick={onAddLabelClick}
            active={active.includes(c.utterance_id)}
            starttime={formatTime(c.starttime)}
            side={c.channel === "ch_0" ? "left" : "right"}
            utterance={c}
          />
        </div>
      </div>
    ));
  }, [transcript, activeUtterances]);

  return (
    <div className={classes.root}>
      <>
        <div className={classes.search}>
          <SearchBar
            value={searchTerm}
            onChange={(newValue) => setSearchTerm(newValue)}
            onRequestSearch={() => doSearch(searchTerm, transcript)}
          />
        </div>
        <div
          aria-label="transcription container"
          className={classes.chatContainer}
          ref={chatContainer}
          id="chatContainer"
        >
          {transcript?.length === 0 && (
            <div className={classes.transcriptionEl}>
              <div style={{ textAlign: "center" }}>
                <SpeakerNotesOffIcon color="action" style={{ margin: "auto 0", fontSize: "40" }} />
                <Typography variant="h6">This call has not been transcribed yet.</Typography>
              </div>
            </div>
          )}
          {msgs}
        </div>
        {/* <LabelDialog utterance={currentUtterance} open={labelDialogOpen} handleClose={handleLabelDialogClose} /> */}
      </>
    </div>
  );
};

export function formatTime(d) {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
  return hDisplay + mDisplay + sDisplay;
}

export default Transcript;
export { Transcript };
