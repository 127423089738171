import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Container, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useStyles } from "./ErrorPage.styles";
import errorLogo from "../../images/errorshapes.png";

const ErrorPage = ({ resetErrorBoundary }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Container className={classes.errorMessContainer}>
        <img className={classes.errorLogo} alt="errorShapes" src={errorLogo} />
        <div className={classes.headerTextContainer}>
          <Typography component="h1" variant="h1" className={classes.title}>
            {" "}
            Oh no!
          </Typography>
          <Typography component="h3" variant="h3" className={classes.subtitle1}>
            Something went wrong...
          </Typography>
          <Typography component="h4" variant="h4" className={classes.subtitle1}>
            {resetErrorBoundary ? "Refresh the page or try again later." : ""}
          </Typography>
        </div>
        {resetErrorBoundary ? (
          <div className={classes.buttons}>
            <Button
              onClick={() => {
                resetErrorBoundary();
                history.replace("/");
              }}
              className={classes.refreshButton}
            >
              Try again
            </Button>
          </div>
        ) : (
          ""
        )}
      </Container>
    </div>
  );
};

export default ErrorPage;
