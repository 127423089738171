import React from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import { SystemStats } from "./components";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      margin: theme.spacing(0),
      height: "100%",
      width: "100%",
    },
  });
});

const Admin = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.root}>
      {/* <UserTable /> */}

      <SystemStats />
    </div>
  );
};

export { Admin };
export default Admin;
