/**
 *
 * @param array Array of objects that require grouping
 * @param key Parameter of objects which will be the key of the grouped array
 */

const groupBy = (array, key) => {
  if (!array) return [];
  return array?.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
};

export default groupBy;
