import React from "react";
import { ListItemText, ListItem, CardHeader, Avatar, IconButton, Collapse } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useStoreActions } from "../../../../hooks";
import { paletteLight } from "../../../../newTheme/palette";
import { Classification } from "../../../../models/Call";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  cardRoot: {
    minWidth: 275,
    margin: theme.spacing(1),
  },
  header: {
    cursor: "pointer",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: paletteLight.secondary.main,
  },
  triggerUtterance: {
    color: paletteLight.secondary.main,
    backgroundColor: paletteLight.background.main,
    "&:hover": {
      backgroundColor: paletteLight.background.dark,
    },
    marginBottom: theme.spacing(1),
  },
  chip: {
    height: "60px",
    width: "100px",
    borderRadius: "28px",
    fontSize: "medium",
  },
  adviser: {
    backgroundColor: paletteLight.primary.main,
  },
  client: {
    backgroundColor: paletteLight.info.main,
  },
  content: {
    height: 0,
    transition: "2s",
  },
  expanded: {
    height: "inherit",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export interface RiskItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  risk: Classification;
}

const RiskItem: React.FC<RiskItemProps> = ({ risk }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [expanded, setExpanded] = React.useState(false);
  const setSelectedTime = useStoreActions((actions) => actions.setSelectedTime);

  const goToTime = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedTime(parseFloat(risk.starttime));
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card key={risk.call_id} className={classes.cardRoot}>
      <CardHeader
        className={classes.header}
        onClick={() => setExpanded(!expanded)}
        avatar={
          risk.channel === "ch_0" ? (
            <Avatar aria-label="recipe" className={classes.adviser}>
              A
            </Avatar>
          ) : (
            <Avatar aria-label="recipe" className={classes.client}>
              M
            </Avatar>
          )
        }
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        title={`${risk.risk} At ${formatTime(risk.starttime)}`}
        // subheader={`At ${formatTime(risk.startTime)}`}
        subheader={`${risk.risk_sub_category}`}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={clsx(classes.content, expanded && classes.expanded)}>
          <ListItem button className={classes.triggerUtterance} onClick={goToTime}>
            <ListItemText primary={risk.utterance} />
          </ListItem>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export { RiskItem };
export default RiskItem;

function formatTime(d) {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
  return hDisplay + mDisplay + sDisplay;
}
