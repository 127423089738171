/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { paletteLight } from "../../../../newTheme/palette";

const useQualitiesStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  title: {
    fontFamily: "poppins",
    textAlign: "center",
  },
  verySatisfied: {
    fontSize: "36px",
    fill: paletteLight.success.main,
  },
  veryDissatisfied: {
    fontSize: "36px",
    fill: paletteLight.error.main,
  },
  satisfied: {
    fontSize: "36px",
    fill: paletteLight.info.main,
  },
}));

interface IconBoxProps {
  softSkills: any;
  label: string;
}

const IconBoxEmoji: React.FunctionComponent<IconBoxProps> = ({ softSkills, label }) => {
  const theme = useTheme();
  const classes = useQualitiesStyles(theme);

  return (
    <div className={classes.root}>
      {softSkills > 0 ? (
        <SentimentVerySatisfiedIcon className={classes.verySatisfied} />
      ) : softSkills < 0 ? (
        <SentimentVeryDissatisfiedIcon className={classes.veryDissatisfied} />
      ) : (
        <SentimentSatisfiedIcon className={classes.satisfied} />
      )}
      <Typography variant="h5" className={classes.title}>
        {label}
      </Typography>
    </div>
  );
};

export { IconBoxEmoji };
export default IconBoxEmoji;
