import axios from "axios";
import { mockUsers } from "./../mocks";
import { CallStatus } from "./../../models";
import { User } from "./../../models/Users";
import { Filters } from "./../../models/filters";
import { buildFilterString } from "./filter";

const baseUrl = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: `${baseUrl}`,
  responseType: "json",
});

export const systemStats = async ({ token, startDate, endDate }) => {
  const url = `${baseUrl}/systemstats?startdate=${startDate || ""}&enddate=${endDate || ""}`;
  const res = await api.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return res.data;
};

export const getCalls = async (
  token: string,
  from: number,
  size: number,
  sortField: string,
  sortOrder: string,
  listStatus: CallStatus,
  search?: string | null,
  filters?: Filters | null,
) => {
  const url = `${baseUrl}/calls?${encodeURI(
    `listStatus=${listStatus}&searchTerm=${search}&from=${from}&size=${size}&sortField=${sortField}&sortOrder=${sortOrder}&${buildFilterString(
      filters,
    )}`,
  )}`;
  const res = await api.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return { results: res.data.results, count: res.data.count };
};

export const getCall = async (id, token) => {
  const url = `${baseUrl}/calls/${id}`;
  const res = await api.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return res.data.results[0]._source;
};

export const deleteCall = async (id, token) => {
  const url = `${baseUrl}/calls/${id}`;
  const res = await api.delete(url, {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

export const getSignedUploadUrl = async (filename, token) => {
  const signedUrl = await api.get(`${baseUrl}/getWritableSignedUploadUrl?fileName=${filename}`, {
    headers: {
      Authorization: token,
    },
  });

  return signedUrl;
};

export const uploadNew = async (file, token, onUploadProgress) => {
  const { name, type } = file;
  const signedUrl = await getSignedUploadUrl(name, token);

  const headers = { "Content-Type": type, headers: { "x-amz-tagging": "blah" } };
  await api.put(`${signedUrl.data["url"]}`, file, { headers, onUploadProgress });
  return {};
};

export const getCallAudio = async (filename: string, token) => {
  const url = `${baseUrl}/getSignedUploadUrl/${filename}`;
  const signedUrl = await api.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return signedUrl.data["url"];
};

export const fetchUsers = async (): Promise<User[]> => {
  return new Promise((resolve) => {
    resolve(mockUsers.data || []);
  });
};

export const updateCall = async (id, token, body) => {
  const url = `${baseUrl}/calls/${id}`;
  const res = await api.post(url, body, {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

export const downloadCallList = async ({ token, startDate, endDate }) => {
  const url = `${baseUrl}/downloadcalllist?startdate=${startDate || ""}&enddate=${endDate || ""}`;
  const res = await api.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return res.data;
};
