/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Typography, ListItem, ListItemText, ListItemSecondaryAction } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: { minWidth: theme.spacing(100) },
  });
});

export interface ListStatProps {
  primary?: string;
  secondary?: string | any;
  value?: string;
}

const ListStat: React.FC<ListStatProps> = ({ primary, secondary, value }) => {
  const classes = useStyles();
  return (
    <ListItem className={classes.root}>
      <ListItemText secondary={secondary}>{primary}</ListItemText>
      <ListItemSecondaryAction>
        <Typography variant="h4">{value}</Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export { ListStat };
