import React, { useState } from "react";
import { createStyles, makeStyles, Paper, Theme, useTheme } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { DataGrid, ColDef, ValueFormatterParams } from "@material-ui/data-grid";
import { fetchUsers } from "./../../../services/api";
import { useQuery } from "react-query";
import { User } from "./../../../models/Users";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: { padding: theme.spacing(1) },
  });
});

const UserTable: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [currentUser, setCurrentUser] = useState<User>();
  const [showCurrentUser, setShowCurrentUser] = useState<boolean>(false);

  const { data: users, error, isLoading } = useQuery("users", async () => {
    const users = await fetchUsers();
    return users;
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showDetail = (params: any) => {
    setCurrentUser(params.data);
    setShowCurrentUser(!showCurrentUser);
  };

  const columns: ColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },
    { field: "lastLogin", headerName: "Last login", width: 130 },
    { field: "roles", headerName: "Roles", width: 130 },
    {
      field: "details",
      headerName: "Details",
      width: 130,
      // eslint-disable-next-line react/display-name
      renderCell: (params: ValueFormatterParams) => {
        return <MoreHorizIcon onClick={() => showDetail(params)} />;
      },
    },
  ];

  if (isLoading) return <div>Loading</div>;
  if (error) return <div>{JSON.stringify(error, null, 2)}</div>;

  return (
    <>
      {showCurrentUser && <pre>{JSON.stringify(currentUser, null, 2)}</pre>}
      <Paper className={classes.root}>
        {users && (
          <DataGrid
            rows={users}
            columns={columns}
            pageSize={20}
            hideFooterPagination={true}
            hideFooterRowCount={true}
          />
        )}
      </Paper>
    </>
  );
};

export { UserTable };
export default UserTable;
