import { Filters } from "./../../models/filters";

const getSelected = (filterType: string, filters: Filters) => {
  return filters[filterType].filters.filter((f) => f.checked === true).map((f) => f.value);
};

export const buildFilterString = (filters: Filters) => {
  const riskScore = getSelected("riskLevel", filters);
  const authenticated = getSelected("preCheck", filters);
  const callOwner = getSelected("callOwner", filters);
  const duration = getSelected("duration", filters);
  const riskType = getSelected("riskType", filters);

  return `filters=${JSON.stringify({
    riskScore,
    authenticated,
    callOwner,
    duration,
    riskType,
  })}`;
};
