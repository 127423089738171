export default {
  colorDefault: {
    backgroundColor: "transparent",
    color: "#fff",
  },
  colorPrimary: {
    backgroundColor: "#fff",
    color: "#1b223a",
  },
};

export const darkAppBar = {
  colorDefault: {
    backgroundColor: "#1b223a",
    color: "#fff",
  },
  colorPrimary: {
    backgroundColor: "#1b223a",
    color: "#fff",
  },
};
