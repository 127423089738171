/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
// import { parseDiarizations } from "./utils";
import { paletteLight } from "../../../../newTheme/palette";

export interface SparklineProps {
  avg?: number;
  data?: number[];
  minMax?: { min: number; max: number };
  height?: string;
  person?: "agent" | "member";
  type?:
    | "area"
    | "line"
    | "bar"
    | "histogram"
    | "pie"
    | "donut"
    | "rangeBar"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "candlestick"
    | "radar"
    | "polarArea";
}

const Sparkline: React.FunctionComponent<SparklineProps> = ({
  avg,
  data,
  height = "32px",
  type = "line",
  person,
  minMax,
}) => {
  const [series, setSeries] = useState(undefined);
  const [options, setOptions] = useState(undefined);

  useEffect(() => {
    let colors = [];
    if (avg) {
      person === "agent"
        ? (colors = ["#d6dae0", paletteLight.primary.main])
        : (colors = ["#d6dae0", paletteLight.info.main]);
    } else {
      person === "agent" ? (colors = [paletteLight.primary.main]) : (colors = [paletteLight.info.main]);
    }

    const _options = {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors,
      stroke: {
        show: true,
        // width: 0.8,
        // lineCap: "round",
      },
      legend: {
        show: false,
      },
      subtitle: {},
      grid: {
        show: false,
        padding: {
          top: -20,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
        intersect: false,
        shared: true,
      },
      yaxis: {
        labels: {
          minWidth: 0,
        },
        show: false,
        min: minMax?.min,
        max: minMax?.max,
      },
      xaxis: {
        tooltip: { enabled: false },
        category: "numeric",
        labels: {
          show: true,
          minHeight: 0,
          maxHeight: 0,
          offsetY: 50,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    };
    setOptions(_options);
  }, [minMax]);

  useEffect(() => {
    let series = [];
    let average = [];

    if (avg) {
      average = Array.from({ length: data.length }).map(() => avg);
    }

    average.length > 0
      ? (series = [
          {
            name: "average",
            data: average,
          },
          {
            name: "client",
            type: type,
            data,
            stroke: {
              width: 0.5,
            },
          },
        ])
      : (series = [
          {
            name: "client",
            type: type,
            data,
            stroke: {
              width: 0.5,
            },
          },
        ]);

    setSeries(series);
  }, [type, data, avg]);

  //   const [options, setoptions] = useState(_options);

  //   useEffect(() => {
  //     setoptions({
  //       chart: {
  //         type: "line",
  //         sparkline: {
  //           enabled: true,
  //         },
  //       },
  //       stroke: {
  //         width: 1,
  //       },
  //       tooltip: {
  //         fixed: {
  //           enabled: false,
  //         },
  //         x: {
  //           show: false,
  //         },
  //         y: {
  //           title: {
  //             formatter: function (seriesName) {
  //               return seriesName;
  //             },
  //           },
  //         },
  //         marker: {
  //           show: false,
  //         },
  //       },
  //     });
  //   }, []);

  return <>{options && <Chart options={options} series={series} type={type} height={height} />}</>;
};

export default Sparkline;
export { Sparkline };
