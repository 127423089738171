import { paletteLight } from "./../../../../newTheme/palette";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  dropzoneOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    background: "rgba(0,0,0,0.5)",
  },
  uploadComplete: {
    textAlign: "center",
    margin: `${theme.spacing(0)} auto`,
    top: "100%",
    backgroundColor: `${paletteLight.success.main}`,
    color: "#fff",
    padding: `${theme.spacing(2)}px`,
    fontSize: `${theme.typography.button.fontSize}`,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "sticky",
    bottom: "0%",
    opacity: 1,
    transition: "opacity 0.2s linear",
  },
  uploadNotComplete: {
    textAlign: "center",
    margin: `${theme.spacing(0)} auto`,
    top: "100%",
    backgroundColor: `${paletteLight.success.main}`,
    color: "#fff",
    padding: `${theme.spacing(2)}px`,
    fontSize: `${theme.typography.button.fontSize}`,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "sticky",
    bottom: "0%",
    transition: "opacity 0.2s linear",
    opacity: 0,
  },
  dropzoneContainer: {
    padding: `${theme.spacing(2)}px`,
    background: "#fff",
    position: "relative",
    display: "grid",
    width: "50%",
    minHeight: "300px",
    top: "30%",
    margin: `${theme.spacing(0)} auto`,
    boxSizing: "border-box",
    transition: "all .15s linear",
    border: "2px solid #d9d9d9",
    borderRadius: "4px",
    cursor: "pointer",
    gridTemplateAreas: `
      'a1 a1 a2 a2'
      'b1 b1 b1 b1'
      `,
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    columnGap: `${theme.spacing(1)}px`,
  },
  dropzone: {
    display: "grid",
    width: "100%",
    textAlign: "center",
    minHeight: "300px",
    alignItems: "center",
    background: "#d9d9d9",
    borderRadius: "4px",
    gridArea: "a1",
  },
  dropzoneSpan: {
    gridTemplateAreas: `
    'a1 a1 a1 a1'
    'b1 b1 b1 b1'
    `,
  },
  uploadIcon: {
    fontSize: "100px",
    margin: `${theme.spacing(0)} auto`,
  },
  uploadFab: {
    position: "absolute",
    right: theme.spacing(1),
    bottom: "3px",
    color: paletteLight.white.main,
    zIndex: 99999,
  },
  thumbsContainer: {
    position: "relative",
    width: "100%",
    border: `solid 1px #e8e8e8`,
    gridArea: "a2",
    gridTemplateColumns: "1fr",
    maxHeight: "300px",
    overflowY: "scroll",
  },
  preview: {
    width: "100%",
    backgroundColor: "#fff",
    height: "50px",
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    alignContent: "center",
    border: "1px solid #e8e8e8",
    marginBottom: `${theme.spacing(1)}px`,
    padding: `${theme.spacing(2)}px`,
  },
  previewIcon: {
    alignSelf: "center",
  },
  previewName: {
    alignSelf: "center",
    color: "#000",
  },
  previewProgress: {
    alignSelf: "center",
    textAlign: "center",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    justifyItems: "center",
  },
  progressCircle: {
    alignSelf: "center",
    scale: 0.9,
  },
  previewButton: {
    alignSelf: "center",
    backgroundColor: paletteLight.success.main,
    position: "relative",
    left: "100%",
    zIndex: 1,
  },
  removePreviewButton: {
    background: "transparent",
    boxShadow: "none",
    position: "relative",
    left: "100%",
    zIndex: 1,
  },
  previewButtonUploading: {
    opacity: 0,
    transition: "opacity 0.2s linear",
    position: "relative",
    left: "100%",
    backgroundColor: "#e8e8e8",
  },
  buttonContainer: {
    width: "100%",
    gridArea: "b1",
    textAlign: "right",
  },
  dropzoneButton: {
    marginTop: `${theme.spacing(1)}px`,
  },
  uploadSnackbar: {
    backgroundColor: paletteLight.success.main,
    color: "#000",
  },
}));
