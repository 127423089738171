import { User } from "./../models/Users";

export const hasRole = (user: User, role: string) => {
  const namespace = "https://avenidetect.com/roles";
  return user[namespace]?.includes(role);
};

export const getTenantName = (user: User) => {
  const namespace = "https://avenidetect.com/tenantName";
  return user[namespace] || "No tenancy";
};

export const extractRoles = (user) => {
  const namespace = "https://avenidetect.com/roles";
  return [...user[namespace]];
};
