/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { useStoreActions } from "./../../../../hooks";
import { Typography } from "@material-ui/core";
import SentimentSatisfiedAltRoundedIcon from "@material-ui/icons/SentimentSatisfiedAltRounded";
import { RiskBar, RiskTabPanel, RiskItem, OverlapCard } from "./index";
import { paletteLight } from "./../../../../newTheme/palette";
// import { Utterance } from "../../../../models/Call";
// import { Overlap } from "../../../../models/Overlap";

export interface RiskInterruptsProps {
  utterances: any;
  interrupts: any;
}

const RiskInterrupts: React.FC<RiskInterruptsProps> = ({ utterances, interrupts }) => {
  const [activeTab, setActiveTab] = useState(0);
  const setSelectedTime = useStoreActions((actions) => actions.setSelectedTime);

  const risks = utterances?.filter((utterance) => utterance?.classifications?.length > 0);

  return (
    <>
      <RiskBar activeTab={activeTab} setActiveTab={setActiveTab} />
      <RiskTabPanel value={activeTab} index={0} padding={3}>
        {risks?.length > 0 ? (
          <div>
            {risks?.map((risk) =>
              risk.classifications?.map((classification) => (
                <RiskItem key={classification.call_classification_id} risk={classification} />
              )),
            )}
          </div>
        ) : (
          <div style={{ textAlign: "center", height: "100%", margin: "2em" }}>
            <SentimentSatisfiedAltRoundedIcon
              color="action"
              style={{ margin: "auto 0", fontSize: "40", color: paletteLight.info.main }}
            />
            <Typography variant="h5">Nothing to see here!</Typography>
            <Typography variant="body1">No risks have been found in this call.</Typography>
          </div>
        )}
      </RiskTabPanel>
      <RiskTabPanel value={activeTab} index={1} padding={3}>
        {interrupts?.length ? (
          <div>
            {interrupts
              .sort((a: any, b: any) => (parseFloat(a.starttime) < parseFloat(b.starttime) ? -1 : 1))
              .map((interrupt) => (
                <OverlapCard key={interrupt.id} overlap={interrupt} onSelected={setSelectedTime} />
              ))}
          </div>
        ) : (
          <div style={{ textAlign: "center", height: "100%", margin: "2em" }}>
            <SentimentSatisfiedAltRoundedIcon
              color="action"
              style={{ margin: "auto 0", fontSize: "40", color: paletteLight.info.main }}
            />
            <Typography variant="h5">Nothing to see here!</Typography>
            <Typography variant="body1">No interruptions have been found in this call.</Typography>
          </div>
        )}
      </RiskTabPanel>
    </>
  );
};

export { RiskInterrupts };
export default RiskInterrupts;
