import React from "react";
import { Toolbar, AppBar, useMediaQuery, useTheme } from "@material-ui/core";
import { CallList, Filters } from "./components";
import { useStyles } from "./CallListPage.styles";

const Callist: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const largeBreak = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.root}>
      {largeBreak ? null : <Filters />}
      <div className={classes.callList}>
        <CallList />
      </div>
      <AppBar position="fixed" color="primary" className={classes.bookEnd}>
        <Toolbar></Toolbar>
      </AppBar>
    </div>
  );
};

export { Callist };
export default Callist;
