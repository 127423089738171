import { makeStyles } from "@material-ui/core";
import { paletteLight } from "./../../newTheme/palette";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: paletteLight.subzeroBlue.main,
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%23056fab' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%2307659d' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%230a5a8f' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%230c5081' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%230e4673' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
      background-attachment: fixed;
      background-size: cover;`,
    height: "100%",
    width: "100%",
  },
  filters: {
    paddingBottom: "64px",
    width: "466px",
    flexGrow: 0,
  },
  callList: {
    paddingBottom: "64px",
    width: "100%",
  },
  bookEnd: {
    backgroundColor: "rgba(27, 34, 58,0.95)",
    position: "absolute",
    top: "auto",
    bottom: 0,
    left: 0,
  },
  drawerTab: {
    position: "absolute",
    left: "-16px",
    top: "50px",
    zIndex: 1,
    transformOrigin: "left top 0",
    backgroundColor: paletteLight.darkBlue.main,
    padding: theme.spacing(1),
    cursor: "pointer",
    borderRadius: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
    color: paletteLight.white.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  drawerPaper: {
    width: "460px",
  },
  drawerHeader: {
    color: paletteLight.white.main,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerIcon: {
    color: paletteLight.white.main,
  },
}));
