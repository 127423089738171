import { Filter, FilterGroup } from "./index";

const preCheckFilters: Filter[] = [
  { value: "true", label: "Authenticated", checked: false },
  { value: "false", label: "Not Authenticated", checked: false },
];

export const preCheckGroup: FilterGroup = {
  title: "Pre-check",
  filters: preCheckFilters,
  group: "preCheck",
};
