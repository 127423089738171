/* eslint-disable @typescript-eslint/no-explicit-any */ import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import ReactWordcloud from "react-wordcloud-poc-temp";
import { aveniPink, orcaBlue, pairPurple, azureBlue, subzeroBlue } from "./../../../newTheme/colors";
import { useStoreActions } from "../../../hooks";
import { Utterance, WordCloud } from "../../../models/Call";

export interface WordCloudProps {
  utterances: Utterance[];
  wordCloud: WordCloud[];
}

const Wordcloud: React.FunctionComponent<WordCloudProps> = ({ utterances, wordCloud }) => {
  const [wordcloud, setWordcloud] = useState([]);
  const [options, setOptions] = useState(undefined);
  const setSelectedTime = useStoreActions((actions) => actions.setSelectedTime);
  const limit = 15;

  useEffect(() => {
    if (!utterances) return;
    utterances.map((u) => {
      return { starttime: u.starttime, utterance: u.utterance };
    });
    setWordcloud(
      wordCloud.slice(0, limit).map((w) => {
        return { text: w.text, value: w.value };
      }),
    );
  }, [utterances, wordCloud]);

  const handleWordClick = useCallback(
    ({ text }) => {
      const utterance = utterances.find((u) => u.utterance.includes(text));
      if (!utterance || !utterance.starttime) return;
      setSelectedTime(parseFloat(utterance.starttime));
    },
    [utterances, setSelectedTime],
  );

  const callbacks = useMemo(() => ({ onWordClick: handleWordClick }), [handleWordClick]);

  useEffect(() => {
    const _options = {
      colors: [aveniPink.main, orcaBlue.main, pairPurple.main, azureBlue.main, subzeroBlue.main],
      enableTooltip: false,
      fontSizes: [22, 32],
      rotations: 2,
      rotationAngles: [0, 90],
      scale: "log",
      padding: 5,
      spiral: "archimedean",
      deterministic: true,
      fontFamily: "poppins",
      fontStyle: "normal",
      fontWeight: "normal",
      transitionDuration: 0,
    };
    setOptions(_options);
  }, []);

  // useEffect(() => {
  // if (!data) return;
  // setWordcloud(
  // data.wordcloud.slice(0, limit).map((w) => {
  // return { text: w.text, value: w.value };
  // }),
  // );
  // }, [data]);

  return wordcloud ? <ReactWordcloud options={options} words={wordcloud} callbacks={callbacks} /> : <>Working...</>;
};

export default Wordcloud;
export { Wordcloud };
