import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./ErrorPage/ErrorPage";
import { useHistory } from "react-router-dom";

export const ErrorCatch: React.FC = ({ children }) => {
  const history = useHistory();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onReset={() => {
        history.replace("/");
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
