import { Filter, FilterGroup } from "./index";

const riskTypeFilters: Filter[] = [
  { value: "Complaint", label: "Complaint", checked: false },
  { value: "Vulnerability", label: "Vulnerable", checked: false },
  { value: "Conduct", label: "Conduct", checked: false },
  { value: "Watchlist", label: "Watchlist", checked: false },
];

export const riskTypeGroup: FilterGroup = {
  title: "Risks",
  filters: riskTypeFilters,
  group: "riskType",
};
