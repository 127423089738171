import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Button, ButtonGroup, Typography } from "@material-ui/core";
import { useStoreState, useStoreActions } from "./../../../../hooks";
import { paletteLight } from "./../../../../newTheme/palette";
import { Filter } from "./../../../../models/filters";
import { hasRole } from "./../../../../utils";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      fontSize: "16px",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    buttonGroup: {},
    button: {
      opacity: 0.5,
    },
    buttonSelected: {
      backgroundColor: "black",
      color: "#fff",
    },
    filterGroup: {
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${paletteLight.subzeroBlue.main}`,
    },
  }),
);

export default function Options() {
  const classes = useStyles();
  const { user } = useAuth0();
  const tempFilterGroups = useStoreState((state) => state.tempFilterGroups);
  const setTempFilterGroups = useStoreActions((action) => action.setTempFilterGroups);
  const { duration, riskLevel, preCheck, riskType, callOwner } = tempFilterGroups;

  const handleDurationClick = (selected: Filter) => {
    const newDuration = {
      ...duration,
      filters: duration.filters.map((f) => {
        if (f.value === selected.value) {
          f.checked = !f.checked;
          return f;
        }
        return f;
      }),
    };
    setTempFilterGroups({ ...tempFilterGroups, duration: newDuration });
  };

  const handleRiskLevelClick = (selected: Filter) => {
    const newRisk = {
      ...riskLevel,
      filters: riskLevel.filters.map((f) => {
        if (f.value === selected.value) {
          f.checked = !f.checked;
          return f;
        }
        return f;
      }),
    };
    setTempFilterGroups({ ...tempFilterGroups, riskLevel: newRisk });
  };

  const handlePreCheckClick = (selected: Filter) => {
    const newPreCheck = {
      ...riskLevel,
      filters: preCheck.filters.map((f) => {
        if (f.value === selected.value) {
          f.checked = !f.checked;
          return f;
        }
        return f;
      }),
    };
    setTempFilterGroups({ ...tempFilterGroups, preCheck: newPreCheck });
  };

  const handleRiskTypeClick = (selected: Filter) => {
    const newRiskType = {
      ...riskType,
      filters: riskType.filters.map((f) => {
        if (f.value === selected.value) {
          f.checked = !f.checked;
          return f;
        }
        return f;
      }),
    };
    setTempFilterGroups({ ...tempFilterGroups, riskType: newRiskType });
  };

  const handleCallOwnersClick = (selected) => {
    const newCallOwner = {
      ...callOwner,
      filters: callOwner.filters.map((f) => {
        if (f.value === selected.value) {
          f.checked = !f.checked;
          return f;
        }
        return f;
      }),
    };
    setTempFilterGroups({ ...tempFilterGroups, callOwner: newCallOwner });
  };

  return (
    <>
      {hasRole(user, "ListCalls") && (
        <div className={classes.filterGroup}>
          <Typography className={classes.title}>Call Owner</Typography>
          <ButtonGroup
            className={classes.buttonGroup}
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
          >
            <Button
              className={!callOwner.filters[0].checked ? classes.buttonSelected : classes.button}
              onClick={() => handleCallOwnersClick(callOwner.filters[0])}
            >
              ALL CALLS
            </Button>
            <Button
              className={callOwner.filters[0].checked ? classes.buttonSelected : classes.button}
              onClick={() => handleCallOwnersClick(callOwner.filters[0])}
            >
              JUST MY CALLS
            </Button>
          </ButtonGroup>
        </div>
      )}

      <div className={classes.filterGroup}>
        <Typography className={classes.title}>Call Length</Typography>
        <ButtonGroup
          className={classes.buttonGroup}
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          {duration.filters.map((f, i) => (
            <Button
              className={f.checked ? classes.buttonSelected : classes.button}
              key={i}
              onClick={() => handleDurationClick(f)}
            >
              {f.label}
            </Button>
          ))}
        </ButtonGroup>
      </div>

      <div className={classes.filterGroup}>
        <Typography className={classes.title}>Risk Level</Typography>
        <ButtonGroup
          className={classes.buttonGroup}
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          {riskLevel.filters.map((f, i) => (
            <Button
              className={f.checked ? classes.buttonSelected : classes.button}
              key={i}
              onClick={() => handleRiskLevelClick(f)}
            >
              {f.label}
            </Button>
          ))}
        </ButtonGroup>
      </div>

      <div className={classes.filterGroup}>
        <Typography className={classes.title}>Pre-check</Typography>
        <ButtonGroup
          className={classes.buttonGroup}
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          {preCheck.filters.map((f, i) => (
            <Button
              className={f.checked ? classes.buttonSelected : classes.button}
              key={i}
              onClick={() => handlePreCheckClick(f)}
            >
              {f.label}
            </Button>
          ))}
        </ButtonGroup>
      </div>

      <div className={classes.filterGroup}>
        <Typography className={classes.title}>Risks</Typography>
        <ButtonGroup
          className={classes.buttonGroup}
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          {riskType.filters.map((f, i) => (
            <Button
              className={f.checked ? classes.buttonSelected : classes.button}
              key={i}
              onClick={() => handleRiskTypeClick(f)}
            >
              {f.label}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </>
  );
}
