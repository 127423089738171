import { palette } from "../palette/palette";

export default {
  root: {
    width: "64px",
  },
  paperAnchorLeft: {
    width: "64px",
    backgroundColor: palette.secondary.main,
  },
};
