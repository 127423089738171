import React from "react";
import { Sentiment, Diarization } from "./index";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Utterance } from "../../../../models/Call";
export const boxes = 80;

const useStyles = makeStyles(() => ({
  chartContainer: {
    display: "flex",
    flexDirection: "column",
  },
  sentiment: {
    paddingLeft: "8px",
    paddingRight: "16px",
  },
  diarization: {
    paddingLeft: "8px",
    paddingRight: "16px",
  },
  divider: {
    paddingLeft: "16px",
    paddingRight: "24px",
  },
}));

export interface TimeSeriesProps {
  utterances: Utterance[];
  duration: number;
}

const TimeSeries: React.FC<TimeSeriesProps> = ({ utterances, duration }) => {
  const classes = useStyles();

  return (
    <div className={classes.chartContainer}>
      <div className={classes.sentiment}>
        <Typography variant="h5">Sentiment</Typography>
        <Sentiment utterances={utterances} />
      </div>
      <div className={classes.diarization}>
        <Typography variant="h5">Diarization</Typography>
        <Diarization duration={duration} utterances={utterances} />
      </div>
    </div>
  );
};

export { TimeSeries };
