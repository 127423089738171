/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import Chart from "react-apexcharts";
import { Utterance } from "../../../../models/Call";
import { useStoreActions } from "./../../../../hooks";
import { paletteLight } from "../../../../newTheme/palette";
import { boxes } from "./TimeSeries";

export interface SentimentProps {
  utterances: Utterance[];
}

export interface SentimentBucket {
  endtime: number;
  starttime: number;
}

const Sentiment: React.FunctionComponent<SentimentProps> = ({ utterances }) => {
  const [series, setSeries] = useState([]);
  const setSelectedTime = useStoreActions((actions) => actions.setSelectedTime);
  const [buckets, setBuckets] = useState<SentimentBucket[]>([]);
  const [averageSentiments, setAverageSentiments] = useState<{ ch0: number; ch1: number }[]>([]);

  const createSeries = (utterances: Utterance[], boxLength: number) => {
    const sentiments = Array.from({ length: boxes }).map((s, i: number) => {
      return { starttime: i * boxLength, endtime: (i + 1) * boxLength - 0.001, utterances: [] };
    });

    utterances.forEach((u: Utterance) => {
      const sIdx = sentiments.findIndex(
        (s) => parseFloat(u.starttime) >= s.starttime * 1 && parseFloat(u.starttime) <= s.endtime * 1,
      );
      sentiments[sIdx]?.utterances.push(u);
    });

    setBuckets(sentiments);

    const sentimentsBuckets = sentiments.map((s) => {
      const results = { ch0: 0, ch1: 0 };
      s.utterances.forEach(({ sentiment, channel }) => {
        const length = s.utterances.length;
        if (!sentiment) return;
        if (channel === "ch_0") results.ch0 += sentiment / length;
        if (channel === "ch_1") results.ch1 += sentiment / length;
      });

      return results;
    });

    setAverageSentiments(sentimentsBuckets);

    return [
      { data: sentimentsBuckets.map((s) => s.ch0), type: "area" },
      { data: sentimentsBuckets.map((s) => s.ch1), type: "area" },
    ];
  };

  useEffect(() => {
    if (!utterances?.length) return;
    const callLength: number = Math.ceil(parseFloat(utterances[utterances.length - 1].endtime));
    setSeries(createSeries(utterances, callLength / boxes));
  }, [utterances]);

  const sentimentOptions = useMemo(() => {
    const onDataPointClicked = (dataPointIndex) => {
      const time = buckets[dataPointIndex].starttime;
      if (!time) return;
      setSelectedTime(time - 2);
    };

    const getMin = () => {
      if (!averageSentiments || averageSentiments.length === 0) return -1;
      const ch0 = averageSentiments
        .map((s) => s.ch0)
        .filter((n) => n !== 0)
        .sort()[0];
      const ch1 = averageSentiments
        .map((s) => s.ch1)
        .filter((n) => n !== 0)
        .sort()[0];
      return ch0 < ch1 ? ch0 : ch1;
    };

    const getMax = () => {
      if (!averageSentiments || averageSentiments.length === 0) return 1;
      const ch0 = averageSentiments
        .map((s) => s.ch0)
        .filter((n) => n !== 0)
        .sort()
        .reverse()[0];
      const ch1 = averageSentiments
        .map((s) => s.ch1)
        .filter((n) => n !== 0)
        .sort()
        .reverse()[0];
      return ch0 > ch1 ? ch0 : ch1;
    };

    return {
      chart: {
        id: "sentiment",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        events: {
          markerClick: (_event, _chartContext, { dataPointIndex }) => {
            onDataPointClicked(dataPointIndex);
          },
        },
      },
      colors: [paletteLight.primary.main, paletteLight.info.main],
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "round",
      },
      legend: {
        show: false,
      },
      markers: {
        size: 0,
        strokeWidth: 0,
        hover: {
          size: 6,
        },
      },
      grid: {
        show: false,
        padding: {
          top: -20,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        intersect: false,
        shared: true,
        followCursor: true,
        x: {
          show: false,
        },
        y: {
          formatter: (value) => {
            if (value === 0) return "0";
            return (value * 100).toFixed(2);
          },
          title: {
            formatter: (seriesName) => (seriesName === "series-1" ? "Agent" : "Member"),
          },
        },
      },
      yaxis: {
        labels: {
          minWidth: 0,
        },
        show: false,
        min: getMin() - 0.1,
        max: getMax() + 0.001,
      },
      xaxis: {
        tooltip: { enabled: false },
        category: "numeric",
        labels: {
          show: true,
          minHeight: 0,
          maxHeight: 0,
          offsetY: 50,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    };
  }, [series, setSelectedTime]);

  return <Chart options={sentimentOptions} series={series} type="area" height="70px" />;
};

export default Sentiment;
export { Sentiment };
