/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from "react";
import { createStyles, Theme, makeStyles, useTheme } from "@material-ui/core/styles";
import { IconButton, Card, Toolbar, LinearProgress, CircularProgress } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getCalls, deleteCall } from "../../../services/api";
import { CallItem, SimpleTabs as Tabs, Search, SortOrder, ErrorMessage } from "./index";
import { paletteLight } from "../../../newTheme/palette";
import { useStoreState } from "./../../../hooks";
import Pagination from "@material-ui/lab/Pagination";
import RefreshIcon from "@material-ui/icons/Refresh";
import { hasRole } from "../../../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      marginTop: "64px",
      color: "#fff",
      height: "calc(100% - 64px)",
      backgroundColor: paletteLight.lightBlue.light,
    },
    toolbar: {
      minHeight: "64px",
      display: "flex",
      borderRadius: "0 2px 0 0",
      backgroundColor: "rgba(79,161,220, 0.9)",
      padding: 0,
      // paddingRight: theme.spacing(1),
      borderBottom: `2px solid ${paletteLight.lightBlue.light}`,
    },
    items: {
      borderRadius: "0 0 2px 0",
      backgroundColor: paletteLight.lightBlue.light,
      overflow: "auto",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      // paddingBottom: "64px",
      position: "relative",
    },
    rowEnd: {
      display: "flex",
      flexGrow: 0,
      justifyContent: "flex-end",
      alignItems: "center",
    },
    sortOrder: {
      [theme.breakpoints.down("lg")]: {
        minWidth: "0px",
      },
      minWidth: "150px",
      flexGrow: 0,
    },
    search: {
      flexGrow: 0,
    },
    pagination: {
      minWidth: "150px",
      textAlign: "right",
      margin: `${theme.spacing(1)}px`,
    },
    paginationContainer: {
      fontSize: "14px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#fff",
      height: "100px",
      marginBottom: theme.spacing(2),
      padding: ` ${theme.spacing(1)}px ${theme.spacing(4)}px`,
      borderRadius: `2px`,
      boxShadow: "0px 0px 3px 1px rgba(89,114,184,.4)",
      color: "#000",
      cursor: "pointer",
    },
    paginationButton: {
      justifyContent: "center",
      color: "rgb(20,98,161)",
    },
    refresh: {
      flexGrow: 0,
      alignSelf: "center",
    },
    refreshButton: {
      // backgroundColor: paletteLight.lightBlue.light,
      "& > span": {
        color: paletteLight.darkBlue.main,
      },
      // "&:hover": {
      //   backgroundColor: paletteLight.darkBlue.main,
      //   "& > span": {
      //     color: paletteLight.white.main,
      //   },
      // },
    },
    refreshIcon: {
      fontSize: "25px",
      fill: paletteLight.white.main,
    },
    progress: {
      textAlign: "center",
      opacity: 0,
    },
    loading: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    loadingIcon: {
      color: paletteLight.secondary.main,
    },
    iconBtnProgress: {
      color: paletteLight.white.main,
    },
  }),
);

const CallList: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { getIdTokenClaims, user, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const timer = useRef<number>(0);
  const searchTerm = useStoreState((state) => state.searchTerm);
  const sort = useStoreState((state) => state.sort);
  const filters = useStoreState((state) => state.filterGroups);
  const doFilter = useStoreState((state) => state.doFilter);
  const callStatus = useStoreState((state) => state.callStatus);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const queryCache = useQueryClient();
  const pageSize = 6;

  const {
    isLoading,
    error,
    data,
    refetch,
    isFetching,
  }: {
    isLoading: boolean;
    error: unknown;
    data: any;
    refetch: () => Promise<any | undefined>;
    isFetching: boolean;
  } = useQuery(
    ["calls", offset, doFilter, callStatus],
    async () => {
      const claims = await getIdTokenClaims();
      if (!claims) {
        await getAccessTokenSilently();
      }
      const token = claims["__raw"];
      if (hasRole(user, "ListCalls") || hasRole(user, "MyCalls")) {
        return await getCalls(token, offset, pageSize, sort.field, sort.order, callStatus, searchTerm, filters);
      }
      return { results: [], count: 0 };
    },
    { keepPreviousData: true },
  );

  const handleDeleteCall = async (id) => {
    mutation.mutate(id);
  };

  const mutation = useMutation(
    async (id) => {
      const claims = await getIdTokenClaims();
      const token = claims["__raw"];
      await deleteCall(id, token);
    },
    {
      onMutate: async (id) => {
        await queryCache.cancelQueries(["calls", offset, doFilter, callStatus]);

        const previousData = queryCache.getQueryData(["calls", offset, doFilter, callStatus]);

        const filteredData = previousData.results.filter((x) => x._id !== id);

        queryCache.setQueryData(["calls", offset, doFilter, callStatus], { results: filteredData });
      },
      onSettled: () => {
        queryCache.invalidateQueries(["calls", offset, doFilter, callStatus]);
      },
    },
  );

  useEffect(() => {
    if (currentPage === 1) {
      return setOffset(0);
    } else {
      return setOffset(pageSize * (currentPage - 1));
    }
  }, [currentPage]);

  useEffect(() => {
    refetch();
  }, [searchTerm, sort, callStatus]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, doFilter]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const refreshCallList = () => {
    if (!loading) {
      setLoading(true);
      timer.current = setTimeout(() => {
        queryCache.invalidateQueries(["calls"]);
        setLoading(false);
      }, 2000);
    }
  };

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  if (isLoading)
    return (
      <div className={classes.loading}>
        <CircularProgress className={classes.loadingIcon} size={60} />
      </div>
    );

  if (error) return <>Error</>;
  const { results: calls, count } = data;

  return (
    <Card className={classes.root} elevation={8}>
      <Toolbar className={classes.toolbar} data-cy="callList-toolbar">
        <Tabs />
        <div className={classes.rowEnd}>
          <div className={classes.sortOrder}>
            <SortOrder />
          </div>
          <div className={classes.search}>
            <Search />
          </div>
          <div className={classes.refresh}>
            <IconButton onClick={refreshCallList} className={classes.refreshButton}>
              {loading ? (
                <CircularProgress className={classes.iconBtnProgress} size={25} />
              ) : (
                <RefreshIcon className={classes.refreshIcon} />
              )}
            </IconButton>
          </div>
        </div>
      </Toolbar>
      {hasRole(user, "ListCalls") || hasRole(user, "MyCalls") ? (
        <>
          <div className={classes.items}>
            {isFetching ? <LinearProgress /> : <LinearProgress className={classes.progress} />}
            {calls.length > 0 ? (
              <>
                {calls
                  .map((call) => call._source)
                  .map((call) => (
                    <CallItem key={call.id} call={call} deleteCall={handleDeleteCall} />
                  ))}
              </>
            ) : (
              <ErrorMessage />
            )}
          </div>
          {calls.length > 0 ? (
            <Pagination
              className={classes.pagination}
              classes={{
                ul: classes.paginationButton,
                root: classes.paginationContainer,
              }}
              color="primary"
              variant="outlined"
              count={Math.ceil(count / pageSize)}
              showFirstButton
              showLastButton
              page={currentPage}
              onChange={handlePageChange}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <ErrorMessage />
      )}
    </Card>
  );
};

export { CallList };
