/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Grid, Paper, IconButton, InputBase, Divider } from "@material-ui/core";
import { makeStyles, createStyles, Theme, useTheme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { useStoreActions, useStoreState } from "../../../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      maxWidth: "800px",
      position: "relative",
      marginLeft: 0,
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
      display: "flex",
      flexDirection: "column",
    },
    root: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
    },
    input: {
      marginLeft: theme.spacing(1),
      padding: "4px 2px",
      flex: 1,
    },
    iconButton: {
      padding: 6,
    },
  }),
);

const Search: React.FunctionComponent = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [text, setText] = useState<string>("");
  const [canClear, setCanClear] = useState<boolean>(false);
  const searchTerm = useStoreState((state) => state.searchTerm);
  const setsearchTerm = useStoreActions((actions) => actions.setsearchTerm);

  const handleSearch = () => {
    setsearchTerm(text);
    setCanClear(true);
  };

  const handleClear = () => {
    setText("");
    setsearchTerm(null);
    setCanClear(false);
  };

  useEffect(() => {
    if (!searchTerm) return;
    setText(searchTerm);
    setCanClear(true);
  }, []);

  return (
    <Grid container className={classes.container}>
      <Paper component="form" className={classes.root} onSubmit={(e) => e.preventDefault()}>
        <InputBase
          className={classes.input}
          placeholder="Search..."
          inputProps={{ "aria-label": "search" }}
          value={text}
          onChange={(event) => setText(event.target.value)}
          data-cy="SearchInput"
        />
        <Divider style={{ flexGrow: 0 }} orientation="vertical" flexItem />
        {canClear ? (
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
            onClick={() => handleClear()}
            data-cy="SearchClearButton"
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
            onClick={() => handleSearch()}
            data-cy="SearchButton"
          >
            <SearchIcon />
          </IconButton>
        )}
      </Paper>
    </Grid>
  );
};

export default Search;
export { Search };
