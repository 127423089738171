import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import * as serviceWorker from "./serviceWorker";
import { StoreProvider } from "easy-peasy";
import { store } from "./store";
import "typeface-poppins";
import "typeface-open-sans";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      cacheLocation={["development", "test"].includes(process.env.NODE_ENV) ? "localstorage" : "memory"}
    >
      <StoreProvider store={store}>
        <App />
      </StoreProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

serviceWorker.unregister();
