"/* eslint-disable @typescript-eslint/no-explicit-any */";
import React from "react";
import cx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import { IconButton } from "@material-ui/core";
// import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import { Utterance } from "../../../../models/Call";
import { paletteLight } from "../../../../newTheme/palette";

const radius = 20;
const size = 32;

const useStyles = makeStyles((theme: Theme) => ({
  iconbutton: {
    height: "48px",
    margin: "auto auto",
  },
  msgFooter: {
    alignSelf: "flex-end",
  },
  labels: {
    margin: "0 4px",
    fontSize: "32px",
    color: "purple",
    "&.complaint": {
      color: "purple",
    },
  },
  starttime: {
    fontSize: "10px !important",
    color: "#999",
    textAlign: "right",
    "&.left": {
      textAlign: "right",
    },
    "&.right": {
      textAlign: "left",
    },
  },
  avatar: {
    width: size,
    height: size,
  },
  active: {
    borderLeft: `5px solid ${paletteLight.primary.main} !important`,
  },
  leftRow: {
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  rightRow: {
    textAlign: "left",
    // textAlign: "right",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  container: {
    marginLeft: theme.spacing(0),
    borderLeft: "5px solid transparent",
    transition: "all .5s ease-in-out",
    paddingRight: "16px",
    "&:hover": {
      "& .addicon": {
        opacity: 1,
      },
    },
  },
  msg: {
    border: "1px solid transparent",
    padding: theme.spacing(1, 2),
    paddingBottom: theme.spacing(0.5),
    borderRadius: 4,
    marginBottom: 4,
    display: "inline-block",
    wordBreak: "break-word",
    fontFamily:
      // eslint-disable-next-line max-len
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontSize: "14px",
  },
  add: {
    opacity: 0,
    transition: "opacity 0.5s",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "5px",
    marginRight: "5px",
  },
  left: {
    borderTopRightRadius: radius,
    borderBottomRightRadius: radius,
    backgroundColor: paletteLight.background.dark,
    color: paletteLight.secondary.main,
  },
  right: {
    borderTopLeftRadius: radius,
    borderBottomLeftRadius: radius,
    backgroundColor: paletteLight.info.main,
    color: paletteLight.white.main,
  },
  leftFirst: {
    borderTopLeftRadius: radius,
  },
  leftLast: {
    borderBottomLeftRadius: radius,
  },
  rightFirst: {
    borderTopRightRadius: radius,
  },
  rightLast: {
    borderBottomRightRadius: radius,
  },
  iconsRight: {
    display: "flex",
  },
  iconsLeft: {
    display: "flex",
    flexDirection: "row-reverse",
  },
}));

export interface ChatMsgProps {
  active: boolean;
  starttime: string;
  side: "left" | "right";
  utterance: Utterance;
  avatar?: string;
  GridContainerProps?: Record<string, unknown>;
  GridItemProps?: Record<string, unknown>;
  AvatarProps?: Record<string, unknown>;
  getTypographyProps?: () => Record<string, unknown>;
  onAddLabelClick?: (utterance: Utterance) => void;
  hideAdd?: boolean;
}

const ChatMsg: React.FC<ChatMsgProps> = ({
  active,
  utterance,
  side,
  // onAddLabelClick,
  GridContainerProps,
  starttime,
  hideAdd,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const attachClass = (index) => {
    if (index === 0) {
      return classes[`${side}First`];
    }
    if (index === Object.keys(utterance).length - 1) {
      return classes[`${side}Last`];
    }
    return "";
  };

  const options = ["Vulnerability", "Conduct", "Complaint"];
  const risks = utterance?.classifications?.map((classification) => classification.risk);

  // const addLabelClicked = useCallback(() => onAddLabelClick(utterance), [utterance, onAddLabelClick]);

  return (
    <Grid
      className={cx(classes.container, active && classes.active)}
      container
      spacing={2}
      justify={side === "right" ? "flex-end" : "flex-start"}
      {...GridContainerProps}
    >
      <Grid item xs={10}>
        <div className={cx(classes[`${side}Row`])}>
          {side === "right" && !hideAdd && (
            <div className={classes.iconsRight}>
              {/* <div className={cx(classes.add, "addicon")}>
                <IconButton onClick={addLabelClicked}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </div> */}
              <div className={cx(classes.msgFooter)}>
                {risks?.map((risk) =>
                  options.includes(risk) ? (
                    <div key={risk} className={cx(classes.labels, risk.toLowerCase())} title={risk}>
                      •
                    </div>
                  ) : (
                    <div></div>
                  ),
                )}
              </div>
            </div>
          )}

          <div className={cx(classes.msg, classes[side], attachClass(0))}>
            {utterance?.utterance}
            <div>
              <Typography className={cx(classes.starttime, classes[side], side)}>{starttime}</Typography>
            </div>
          </div>
          {side === "left" && !hideAdd && (
            <div className={classes.iconsLeft}>
              {/* <div className={cx(classes.add, "addicon")}>
                <IconButton onClick={addLabelClicked}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </div> */}
              <div className={cx(classes.msgFooter)}>
                {risks?.map((risk) =>
                  options.includes(risk) ? (
                    <div key={risk} className={cx(classes.labels, risk.toLowerCase())} title={risk}>
                      •
                    </div>
                  ) : (
                    <div></div>
                  ),
                )}
              </div>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ChatMsg;
