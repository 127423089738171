import { palette } from "../palette/palette";

export default {
  root: {
    width: "100%",
  },
  groupedContainedPrimary: {
    width: "100%",
    "&:not(:last-child)": {
      borderRight: `1px solid ${palette.black.main}`,
    },
    color: palette.white.main,
    backgroundColor: palette.subzeroBlue.main,
    "&:hover": {
      backgroundColor: palette.black.main,
      color: palette.white.main,
    },
  },
};
