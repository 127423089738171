import React from "react";
import { makeStyles, createStyles, useTheme, Theme } from "@material-ui/core/styles";
import { Typography, Container, Button } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import errorLogo from "../../images/errorshapes.png";
import { paletteLight } from "./../../newTheme/palette";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      backgroundColor: "white !important",
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
    },
    errorMessContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      color: paletteLight.primary.main,
      margin: theme.spacing(2),
    },
    subtitle1: {
      color: paletteLight.secondary.main,
      lineHeight: "120%",
      margin: theme.spacing(2),
    },
    errorLogo: {
      height: "300px",
      width: "auto",
    },
    headerTextContainer: {
      textAlign: "center",
      marginBottom: theme.spacing(8),
      fontFamily: "poppins",
    },
    refreshButton: {
      width: "15%",
      border: "1px solid",
      borderColor: paletteLight.primary.main,
      color: paletteLight.primary.main,
      borderRadius: "30px",
      fontFamily: "poppins",
      fontWeight: "bold",
      padding: theme.spacing(2),
      "&:hover": {
        color: "#fff",
        backgroundColor: paletteLight.primary.main,
        cursor: "pointer",
      },
      margin: "5px",
    },
    buttons: {
      width: "75%",
      display: "flex",
      justifyContent: "center",
    },
  });
});

export const UserNotVerified: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { logout } = useAuth0();

  return (
    <div className={classes.root}>
      <Container className={classes.errorMessContainer}>
        <img className={classes.errorLogo} alt="errorShapes" src={errorLogo} />
        <div className={classes.headerTextContainer}>
          <Typography component="h1" variant="h1" className={classes.title}>
            Oh no!
          </Typography>
          <Typography component="h3" variant="h3" className={classes.subtitle1}>
            Something went wrong...
          </Typography>
          <Typography component="h4" variant="h4" className={classes.subtitle1}>
            Your user account setup is not complete. Please try again later.
          </Typography>
        </div>
        <div className={classes.buttons}>
          <Button onClick={() => logout()} className={classes.refreshButton}>
            Try again
          </Button>
        </div>
      </Container>
    </div>
  );
};
