/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import cx from "clsx";
import { paletteLight } from "./../../../newTheme/palette";
// import { CallDetails } from "./../../../models/Call";
import { Typography } from "@material-ui/core";
// import { CallOverview } from "../../../models/Call";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(0),
    display: "flex",
    flexDirection: "row",
  },
  column1: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "50%",
  },
  column2: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "50%",
  },
  paper: {
    height: "36px",
    padding: theme.spacing(0.5),
    color: "white",
    margin: theme.spacing(0.3),
    cursor: "pointer",
    "&.check": {
      backgroundColor: paletteLight.pairPurple.main,
    },
  },
  name: {
    margin: "auto auto",
    textAlign: "center",
    "&.check": {
      opacity: 1,
      color: "#fff",
      backgroundColor: "transparent",
    },
    "&.cross": {
      opacity: 0.6,
      color: paletteLight.secondary.main,
    },
    transition: "color 0.25s",
  },
}));

export interface RiskBoxesProps {
  utteranceClassifications: any;
}

// const riskList = [
//   { name: "Vulnerability", column: "vulnerability" },
//   { name: "Complaint", column: "complaint" },
//   { name: "Conduct", column: "conduct" },
//   { name: "Watchlists", column: "financial_crime" },
// ];

const RiskBoxes: React.FC<RiskBoxesProps> = ({ utteranceClassifications }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  // const [_riskList] = useState(riskList);
  // const risks = call.classifications?.map((c) => c.risk)?.filter((v, i, a) => a.indexOf(v) === i) || [];

  const risks = new Set();

  utteranceClassifications?.forEach((classification) => {
    risks.add(classification.risk);
  });

  return (
    <div className={classes.root}>
      <div className={classes.column1}>
        <Paper className={cx(classes.paper, risks.has("Vulnerability") ? "check" : "cross")}>
          <div className={cx(classes.name, risks.has("Vulnerability") ? "check" : "cross")}>
            <Typography variant="h5">Vulnerability</Typography>
          </div>
        </Paper>
        <Paper className={cx(classes.paper, risks.has("Complaint") ? "check" : "cross")}>
          <div className={cx(classes.name, risks.has("Complaint") ? "check" : "cross")}>
            <Typography variant="h5">Complaint</Typography>
          </div>
        </Paper>
      </div>
      <div className={classes.column2}>
        <Paper className={cx(classes.paper, risks.has("Conduct") ? "check" : "cross")}>
          <div className={cx(classes.name, risks.has("Conduct") ? "check" : "cross")}>
            <Typography variant="h5">Conduct</Typography>
          </div>
        </Paper>
        <Paper className={cx(classes.paper, risks.has("Watchlist") ? "check" : "cross")}>
          <div className={cx(classes.name, risks.has("Watchlist") ? "check" : "cross")}>
            <Typography variant="h5">Watchlist</Typography>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export { RiskBoxes };
export default RiskBoxes;
