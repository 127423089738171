import React, { useState, useEffect, useMemo } from "react";
import Chart from "react-apexcharts";
import { useStoreActions } from "../../../../hooks";
import { Utterance } from "../../../../models/Call";
import { boxes } from "./TimeSeries";
import { paletteLight } from "../../../../newTheme/palette";

export interface DiarizationProps {
  duration: number;
  utterances: Utterance[];
}

const Diarization: React.FunctionComponent<DiarizationProps> = ({ duration, utterances }) => {
  const [series, setSeries] = useState([]);
  const setSelectedTime = useStoreActions((actions) => actions.setSelectedTime);

  useEffect(() => {
    if (!utterances?.length) return;
    const transformUtterances = (utterances: Utterance[]) => {
      const callLength: number = duration;
      const boxLength = callLength / boxes;

      const buckets = Array.from({ length: boxes }).map((d, i: number) => {
        return { starttime: i * boxLength, endtime: (i + 1) * boxLength - 0.001, utterances: [] };
      });

      buckets.forEach((bucket) => {
        const utterance = utterances.filter((u) => {
          return (
            (parseFloat(u.starttime) >= bucket.starttime && parseFloat(u.starttime) <= bucket.endtime) ||
            (parseFloat(u.starttime) <= bucket.starttime && parseFloat(u.endtime) >= bucket.starttime)
          );
        });
        if (utterance.length > 0) bucket.utterances = [...bucket.utterances, ...utterance];
      });

      const ch0Buckets = buckets.map(({ starttime, endtime, utterances }) => {
        const ch0Utterances = utterances.filter((u) => u?.channel === "ch_0" && !!u);
        if (ch0Utterances.length === 0) return [{ x: `${starttime} - ${endtime}`, y: 0, startTime: starttime }];
        const res = ch0Utterances.map((u) => {
          const utteranceLength = Math.min(parseFloat(u?.endtime), endtime) - parseFloat(u?.starttime);
          const time = boxLength - utteranceLength;
          const percent = time < 0 ? 1 : time / boxLength;
          return { x: `${starttime} - ${endtime}`, y: percent, startTime: starttime };
        }) || [{ x: `${starttime} - ${endtime}`, y: 0, startTime: starttime }];

        return res.reduce((a, b) => {
          return { ...b, y: a.y + b.y };
        });
      });

      const ch1Buckets = buckets.map(({ starttime, endtime, utterances }) => {
        const ch1Utterances = utterances.filter((u) => u?.channel === "ch_1" && !!u);
        if (ch1Utterances.length === 0) return [{ x: `${starttime} - ${endtime}`, y: 0, startTime: starttime }];
        const res = ch1Utterances.map((u) => {
          const utteranceLength = Math.min(parseFloat(u?.endtime), endtime) - parseFloat(u?.starttime);
          const time = boxLength - utteranceLength;
          const percent = time < 0 ? 1 : time / boxLength;
          return { x: `${starttime} - ${endtime}`, y: percent, startTime: starttime };
        }) || [{ x: `${starttime} - ${endtime}`, y: 0, startTime: starttime }];
        return res.reduce((a, b) => {
          return { ...b, y: a.y + b.y };
        });
      });

      return [{ data: ch0Buckets.flat() }, { data: ch1Buckets.flat() }];
    };
    setSeries(transformUtterances(utterances));
  }, [duration, utterances]);

  const options = useMemo(() => {
    const onDataPointClicked = ({ dataPointIndex, seriesIndex }) => {
      const utterance = series[seriesIndex].data[dataPointIndex];
      if (!utterance.startTime) return;
      setSelectedTime(utterance.startTime);
    };
    return {
      chart: {
        id: "diarization1",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        events: {
          dataPointSelection: (_event, _chartContext, config) => {
            const { dataPointIndex, seriesIndex } = config;
            onDataPointClicked({ dataPointIndex, seriesIndex });
          },
        },
      },
      colors: [paletteLight.primary.main, paletteLight.info.main],
      plotOptions: {
        heatmap: {
          radius: 5,
          shadeIntensity: 1,
          colorScale: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: "#F5F5F5",
                name: "zero",
              },
            ],
          },
        },
      },
      // subtitle: {
      //   text: "Diarization",
      //   align: "left",
      //   offsetY: 2,
      //   style: {
      //     fontFamily: "poppins",
      //   },
      // },
      tooltip: {
        enabled: false,
      },
      yaxis: {
        labels: {
          minWidth: 0,
        },
        show: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
        padding: {
          top: -20,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        category: "numeric",
        labels: {
          show: true,
          minHeight: 0,
          maxHeight: 0,
          offsetY: 50,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: true,
        },
      },
    };
  }, [series, setSelectedTime]);

  return <Chart options={options} series={series} type="heatmap" height="60px" />;
};

export default Diarization;
export { Diarization };
