/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { createStyles, makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  MenuItem,
  FormControl,
  Select,
  InputBase,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useStoreState, useStoreActions } from "./../../../hooks";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
import SortIcon from "@material-ui/icons/Sort";
import CheckIcon from "@material-ui/icons/Check";

const options = [
  { value: "dateDesc", label: "Newest Calls" },
  { value: "date", label: "Oldest Calls" },
  { value: "filename", label: "A-Z" },
  { value: "riskScore", label: "Risk Low to High" },
  { value: "riskScoreDesc", label: "Risk High to Low" },
];

// const ITEM_HEIGHT = 48;

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "-2px",
      width: "100%",
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      width: "120px",
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      fontSize: 16,
      padding: "2px 26px 2px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }),
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    label: {
      fontSize: "16px",
      color: "white",
      marginRight: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
      padding: "2px 4px",
    },
    menuItem: {
      position: "relative",
      paddingLeft: "35px",
    },
    menuItemIcon: {
      position: "absolute",
      left: theme.spacing(1),
      height: "100%",
      color: "#000",
    },
    sortIcon: {
      color: theme.palette.white.main,
    },
  }),
);

const sortConversion = (sort: string) => {
  switch (sort) {
    case "date":
      return { field: "created_at", order: "asc", value: "date" };
    case "dateDesc":
      return { field: "created_at", order: "desc", value: "dateDesc" };
    case "filename":
      return { field: "uploaded_filename", order: "asc", value: "filename" };
    case "filenameDesc":
      return { field: "uploaded_filename", order: "desc", value: "filenameDesc" };
    case "riskScore":
      return { field: "risk_score", order: "asc", value: "riskScore" };
    case "riskScoreDesc":
      return { field: "risk_score", order: "desc", value: "riskScoreDesc" };
    default:
      return { field: "created_at", order: "desc", value: "dateDesc" };
  }
};

const SortOrder = () => {
  const theme = useTheme();
  const classes = useStyles();
  const largeBreak = useMediaQuery(theme.breakpoints.down("lg"));
  const sort = useStoreState((state) => state.sort);
  const setSort = useStoreActions((actions) => actions.setSort);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: any) => {
    const newValue = event.target.value;
    setSort(sortConversion(newValue));
  };

  const handleMenuChange = (value: string) => {
    setSort(sortConversion(value));
    handleClose();
  };

  return (
    <div className={classes.root}>
      {largeBreak ? (
        <>
          <IconButton aria-label="sort" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
            <SortIcon className={classes.sortIcon} />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            // PaperProps={{
            //   style: {
            //     maxHeight: ITEM_HEIGHT * 4.5,
            //     width: "20ch",
            //   },
            // }}
          >
            {options.map((option) => (
              <MenuItem
                key={option.value}
                selected={option.value === sort.value}
                value={option.value}
                onClick={() => handleMenuChange(option.value)}
                className={classes.menuItem}
              >
                {option.value === sort.value ? <CheckIcon className={classes.menuItemIcon} /> : ""}
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <>
          <Typography className={classes.label}>Sort by</Typography>
          <Paper>
            <FormControl className={classes.margin}>
              <Select value={sort.value} onChange={handleChange} input={<BootstrapInput />}>
                <MenuItem value={"dateDesc"}>Newest Calls</MenuItem>
                <MenuItem value={"date"}>Oldest Calls</MenuItem>
                <MenuItem value={"filename"}>A-Z</MenuItem>
                <MenuItem value={"filenameDesc"}>Z-A</MenuItem>
                <MenuItem value={"riskScore"}>Risk Low to High</MenuItem>
                <MenuItem value={"riskScoreDesc"}>Risk High to Low</MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </>
      )}
    </div>
  );
};

export { SortOrder };
export default SortOrder;
