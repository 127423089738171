/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles, createStyles, useTheme, Theme } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getCall } from "./../../services/api";
import { AppBar, Button, Paper, Toolbar } from "@material-ui/core";
import cx from "clsx";
import HomeIcon from "@material-ui/icons/Home";
import { paletteLight } from "./../../newTheme/palette";
import { useHistory } from "react-router-dom";
import {
  AudioPlayer,
  RiskBoxes,
  Dashboard,
  Wordcloud,
  RiskInterrupts,
  OnHoldBox,
  Transcript,
  IconBoxEmoji,
  TimeSeries,
  NoteField,
} from "./Panels";
import { CallDetails } from "./../../components/CallDetails";
import { CallDetail } from "./../../models/Call";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      border: "rgb(189,214,234)",
      borderBottom: `0px solid ${paletteLight.secondary.main}`,
      "background-color": "rgb(189,214,234)",
      "font-family": "poppins",
      "font-weight": "400",
      color: "#393939",
      height: "calc(100vh - 48px)",
      padding: theme.spacing(1),
    },
    gridContainer: {
      minWidth: "1367px",
      display: "grid",
      gridTemplateColumns: "400px 300px 100px minmax(350px,1fr) minmax(350px,1fr)",
      gridTemplateRows: "100px 100px 100px 1fr 90px 200px",
      gridGap: theme.spacing(1),
      height: "100%",
      gridTemplateAreas: `
      'a1 a1 a1 a2 a3'
      'b1 b1 i1 e1 a3'
      'b1 b1 i2 e1 a3'
      'e2 c2 c2 e1 a3'
      'e2 c2 c2 e1 h2'
      'f1 f1 f1 f1 f1'
      `,
    },
    audioBox: {
      backgroundColor: "green",
      gridArea: "h2",
    },
    notes: {
      gridArea: "e2",
    },
    riskLevel: {
      gridArea: "a2",
    },
    riskBoxes: {
      gridArea: "a2",
    },
    overallRisk: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    callDetails: {
      gridArea: "a1",
    },
    transcript: {
      gridArea: "a3",
    },
    dashboard: {
      gridArea: "b1",
    },
    keyPhrases: {
      gridArea: "b2",
    },
    wordCloud: {
      gridArea: "c2",
    },
    riskInterrupts: {
      gridArea: "e1",
    },
    noteField: {
      gridArea: "e2",
    },
    timeSeries: {
      gridArea: "f1",
    },
    callQualities: {
      gridArea: "d2",
    },
    softSkills: { gridArea: "i1" },
    onhold: { gridArea: "i2" },
    paper: {
      height: "100%",
      padding: theme.spacing(1),
      overflow: "auto",
    },
    appBar: {
      height: "48px",
      backgroundColor: paletteLight.secondary.main,
      position: "absolute",
      top: "auto",
      bottom: 0,
      boxShadow: "none",
    },
    bottomToolbar: {
      minHeight: "auto",
      height: "48px",
      display: "flex",
      justifyContent: "space-between",
      padding: 0,
    },
    homeButton: {
      width: "200px",
      height: "48px",
      borderRadius: 0,
    },
    backButton: {
      width: "200px",
      height: "48px",
      borderRadius: 0,
    },
    nextButton: {
      width: "200px",
      height: "48px",
      borderRadius: 0,
    },
    lockIcon: {
      fill: paletteLight.info.main,
    },
    placeholder: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  });
});

const CallPage = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { id }: { id: string } = useParams();
  const { getIdTokenClaims } = useAuth0();
  const history = useHistory();

  const navigateHome = () => {
    history.replace("/");
  };

  const { isLoading, error, data: callOverview }: { isLoading: boolean; error: unknown; data: CallDetail } = useQuery(
    ["call", id, "call_vw"],
    async () => {
      const claims = await getIdTokenClaims();
      const token = claims["__raw"];
      return getCall(id, token);
    },
  );

  if (isLoading) return <div className={classes.root}>Loading</div>;
  if (error) return <div className={classes.root}>{error}</div>;

  const { filename, detail, summary, uploaded_filename, risk_score, duration, status } = callOverview;

  const { utteranceClassifications, utterances, overlap, soft_skills, wordcloud, note, averagePaceClarity } = detail;

  const items = [
    {
      key: "audioBox",
      item: <AudioPlayer filename={filename} />,
    },
    {
      key: "riskBoxes",
      item: <RiskBoxes utteranceClassifications={utteranceClassifications} />,
    },
    {
      key: "callDetails",
      item: (
        <CallDetails
          summary={{
            authenticated: summary.authenticated,
            uploaded_filename: uploaded_filename,
            risk_score: risk_score,
            duration: duration,
          }}
        />
      ),
    },
    {
      key: "transcript",
      item: <Transcript transcript={utterances} />,
    },
    {
      key: "dashboard",
      item: <Dashboard utterances={utterances} overlap={overlap} avg={averagePaceClarity} />,
    },
    {
      key: "softSkills",
      item: <IconBoxEmoji label="Soft Skills" softSkills={soft_skills} />,
    },
    {
      key: "onhold",
      item: <OnHoldBox duration={duration} />,
    },
    {
      key: "notes",
      item: <NoteField id={id} note={note} status={status} />,
    },
    {
      key: "wordCloud",
      item: <Wordcloud utterances={utterances} wordCloud={wordcloud} />,
    },
    {
      key: "riskInterrupts",
      item: <RiskInterrupts utterances={utterances} interrupts={overlap} />,
    },
    {
      key: "timeSeries",
      item: <TimeSeries utterances={utterances} duration={duration} />,
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.gridContainer}>
        {items.map(({ item, key }) => (
          <div key={key} className={classes[key]}>
            <Paper className={cx(classes.paper, classes[`${item.key}Paper`])}>{item}</Paper>
          </div>
        ))}
      </div>

      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar className={classes.bottomToolbar}>
          <Button
            onClick={() => navigateHome()}
            color="primary"
            size="large"
            variant="contained"
            className={classes.homeButton}
            startIcon={<HomeIcon />}
          >
            Call List
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default CallPage;
