import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { updateCall } from "../../../../services/api";
import { CallDetails } from "../../../../models/Call";
import { CallStatus as Status } from "./../../../../models";
import clsx from "clsx";
import {
  Grid,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { useStoreActions } from "./../../../../hooks";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { paletteLight } from "../../../../newTheme/palette";

const useStyles = makeStyles(() => ({
  splitButton: {
    maxWidth: "10px",
  },
  popper: {
    zIndex: 1,
  },
  pending: {
    backgroundColor: paletteLight.info.main,
    color: paletteLight.white.main,
    "&:hover": {
      opacity: 0.6,
    },
    "&.MuiButtonGroup-groupedContained": {
      "&:hover": {
        backgroundColor: paletteLight.info.main,
      },
    },
    "&.MuiButtonGroup-groupedContainedHorizontal:not(:last-child)": {
      borderRight: `1px solid ${paletteLight.info.main}`,
    },
  },
  complete: {
    backgroundColor: paletteLight.success.main,
    color: paletteLight.white.main,
    "&:hover": {
      opacity: 0.6,
    },
    "&.MuiButtonGroup-groupedContained": {
      "&:hover": {
        backgroundColor: paletteLight.success.main,
      },
    },
    "&.MuiButtonGroup-groupedContainedHorizontal:not(:last-child)": {
      borderRight: `1px solid ${paletteLight.success.main}`,
    },
  },
  unassessable: {
    backgroundColor: paletteLight.error.main,
    color: paletteLight.white.main,
    "&:hover": {
      opacity: 0.6,
    },
    "&.MuiButtonGroup-groupedContained": {
      "&:hover": {
        backgroundColor: paletteLight.error.main,
      },
    },
    "&.MuiButtonGroup-groupedContainedHorizontal:not(:last-child)": {
      borderRight: `1px solid ${paletteLight.error.main}`,
    },
  },
}));

export interface StatusProps {
  id: string;
  status: "pending" | "complete" | "unassessable";
}

const options: Status[] = ["pending", "complete", "unassessable"];

const CallStatus: React.FunctionComponent<StatusProps> = ({ id, status }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const client = useQueryClient();
  const [value, setValue] = useState<Status>(status);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { getIdTokenClaims } = useAuth0();
  const [selectedIndex, setSelectedIndex] = useState(options.indexOf(status));
  const setCallStatus = useStoreActions((actions) => actions.setCallStatus);
  const { mutate } = useMutation(async (value) => {
    if (!value) return;
    const claims = await getIdTokenClaims();
    const token = claims["__raw"];
    updateCall(id, token, { status: value });
  });

  const handleMenuItemClick = (event) => {
    setSelectedIndex(event.target.value);
    setValue(options[event.target.value]);
    setOpen(false);
  };

  useEffect(() => {
    mutate(value, {
      onMutate: async () => {
        await client.cancelQueries([status, id]);

        const previousValue = client.getQueryData([status, id]);

        client.setQueryData([status, id], (old: CallDetails) => ({
          ...old,
          status: old.status,
        }));

        return previousValue;
      },
      onError: (previousValue) => client.setQueryData([status, id], previousValue),
      onSettled: () => {
        client.invalidateQueries([status, id]);
      },
    });
    setCallStatus(value);
  }, [value]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="flex-end">
      <Grid item xs={12}>
        <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
          <Button
            style={{ cursor: "default" }}
            className={clsx(
              options[selectedIndex] === "pending"
                ? classes.pending
                : options[selectedIndex] === "complete"
                ? classes.complete
                : classes.unassessable,
            )}
          >
            {options[selectedIndex]}
          </Button>
          <Button
            className={clsx(
              options[selectedIndex] === "pending"
                ? classes.pending
                : options[selectedIndex] === "complete"
                ? classes.complete
                : classes.unassessable,
            )}
            color="primary"
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select status"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className={classes.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event)}
                        value={index}
                      >
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export { CallStatus };
export default CallStatus;
