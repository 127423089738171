import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import { paletteLight } from "../../../newTheme/palette";

const useStyles = makeStyles(() =>
  createStyles({
    root: { textAlign: "center", height: "100%", margin: "2em" },
    icon: { margin: "auto 0", fontSize: "60", color: "#1462A1" },
    text: { color: paletteLight.darkBlue.main },
  }),
);

const ErrorMessage: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MoodBadIcon className={classes.icon} />
      <Typography variant="h3" className={classes.text}>
        Oh no! We have no calls to show.
      </Typography>
      <Typography variant="h4" className={classes.text}>
        Please adjust your filters or search terms and try again.
      </Typography>
    </div>
  );
};

export { ErrorMessage };
export default ErrorMessage;
