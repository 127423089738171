import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { CallDetails } from "./../../../../models/Call";
import { TextField, Typography } from "@material-ui/core";
import { CallStatus } from "./CallStatus";
import { updateCall } from "../../../../services/api";
import { useAuth0 } from "@auth0/auth0-react";
import { paletteLight } from "../../../../newTheme/palette";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",

    height: "100%",
  },
  form: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  saveBtn: {
    backgroundColor: paletteLight.primary.main,
    color: paletteLight.white.main,
    "&:hover": {
      backgroundColor: paletteLight.primary.main,
      opacity: 0.4,
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "50px",
    alignSelf: "flex-end",
  },
}));

export interface NoteFieldProps {
  id: string;
  note: string;
  status: "pending" | "complete" | "unassessable";
}

const NoteField: React.FunctionComponent<NoteFieldProps> = ({ id, note, status }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [value, setValue] = useState<string>("");
  const [savedValue, setSavedValue] = useState<string>("");
  const { getIdTokenClaims } = useAuth0();
  const client = useQueryClient();
  const { mutate } = useMutation(async (value: string) => {
    if (!value) return;
    const claims = await getIdTokenClaims();
    const token = claims["__raw"];
    updateCall(id, token, { note: value });
  });

  useEffect(() => {
    mutate(savedValue, {
      onMutate: () => {
        client.cancelQueries([note, id]);
        const previousValue = client.getQueryData([note, id]);
        client.setQueryData([note, id], (old: CallDetails) => ({
          ...old,
          note: old.note,
        }));
        return previousValue;
      },
      onError: (previousValue) => client.setQueryData([note, id], previousValue),
      onSettled: () => {
        client.invalidateQueries([note, id]);
      },
    });
  }, [savedValue]);

  useEffect(() => {
    if (!id) return;
    setValue(note);
  }, [id]);

  const handleSave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSavedValue(value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          Call status
        </Typography>
        <CallStatus id={id} status={status} />
      </div>
      <form className={classes.form} noValidate autoComplete="off">
        <TextField
          id="outlined-textarea"
          label=""
          placeholder="Add notes about the call"
          multiline
          rows={11}
          variant="outlined"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button className={classes.saveBtn} type="submit" onClick={handleSave}>
          Save
        </Button>
      </form>
    </div>
  );
};

export { NoteField };
export default NoteField;
