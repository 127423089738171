import { Filter, FilterGroup } from "./index";

const durationFilters: Filter[] = [
  { value: "tooShort", label: "0-2 MIN", checked: false },
  { value: "short", label: "2-10 MIN", checked: false },
  { value: "mediumLength", label: "10-12 MIN", checked: false },
  { value: "long", label: "12+ MIN", checked: false },
];

export const durationGroup: FilterGroup = {
  title: "Duration",
  filters: durationFilters,
  group: "duration",
};
