import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { useDropzone } from "react-dropzone";
import { Fab, Card, Typography, Snackbar, Slide, IconButton, SnackbarContent } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";
import { useStyles } from "./Dropzone.styles";
import CheckIcon from "@material-ui/icons/Check";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CancelIcon from "@material-ui/icons/Cancel";
import { Button } from "@material-ui/core";
import { uploadNew } from "../../../../services/api";
import clsx from "clsx";

const addIdToFiles = async (event) => {
  const files = [];
  const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);
    Object.defineProperty(file, "id", { value: uuid() });
    files.push(file);
  }

  return files;
};

const SlideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
};

const Dropzone = () => {
  const [files, setFiles] = useState([]);
  const classes = useStyles();
  const { getIdTokenClaims } = useAuth0();
  const [isVisible, setIsVisible] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [completedFiles, setCompletedFiles] = useState(0);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["audio/*", ".csv"],
    getFilesFromEvent: (event) => addIdToFiles(event),
    onDrop: (acceptedFiles) => {
      const newFiles = files.concat(acceptedFiles);
      setFiles(newFiles);
      setIsComplete(false);
    },
  });

  useEffect(() => {
    const completedFiles = files.filter((file) => file.progress !== 100);
    if (files.length > 0 && completedFiles.length === 0) {
      setIsComplete(true);
      setIsUploading(false);
      setCompletedFiles(files.length);
      setFiles([]);
      setIsVisible(false);
    }
  }, [files]);

  const handleUpload = async () => {
    setIsUploading(true);
    const claims = await getIdTokenClaims();
    const token = claims["__raw"];
    files.forEach((file, index) => {
      uploadNew(file, token, (event) => {
        file.progress = Math.round((100 * event.loaded) / event.total);
        const newFiles = [...files];
        newFiles[index] = file;
        setFiles(newFiles);
      });
    });
  };

  const handleRemove = (event) => {
    files.length === 1 ? setFiles([]) : setFiles(() => files.filter((file) => file.id !== event.target.dataset.file));
  };

  const handleClose = () => {
    setFiles([]);
    setIsVisible(false);
    setIsUploading(false);
  };

  const handleDismissToast = () => {
    setIsComplete(false);
  };

  return (
    <>
      <Fab color="primary" className={classes.uploadFab} onClick={() => setIsVisible(true)} data-cy="UploadButton">
        <AddIcon />
      </Fab>

      {isVisible ? (
        <div className={classes.dropzoneOverlay} data-cy="DropzoneArea">
          <Card
            className={
              files.length > 0 ? classes.dropzoneContainer : clsx(classes.dropzoneContainer, classes.dropzoneSpan)
            }
          >
            {files.length > 0 ? (
              <aside className={classes.thumbsContainer}>
                {files.map((file) => (
                  <div className={classes.preview} key={file.id}>
                    <Typography className={classes.previewName}>{file.name}</Typography>
                    <div className={classes.previewProgress}>
                      {file.progress === 100 ? (
                        <Fab
                          aria-label="save"
                          className={file.progress < 100 ? classes.previewButtonUploading : classes.previewButton}
                          size="small"
                        >
                          <CheckIcon />
                        </Fab>
                      ) : (
                        <Fab
                          aria-label="remove"
                          className={classes.removePreviewButton}
                          size="small"
                          onClick={handleRemove}
                        >
                          <CancelIcon data-file={file.id} />
                        </Fab>
                      )}
                      <CircularProgress
                        variant="determinate"
                        value={file.progress}
                        className={classes.progressCircle}
                      />
                    </div>
                  </div>
                ))}
              </aside>
            ) : (
              ""
            )}

            <div {...getRootProps({ className: classes.dropzone })}>
              <input {...getInputProps()} data-cy="UploadArea" />
              <CloudUploadIcon className={classes.uploadIcon} />
              <p>Drag and drop some files here, or click to select files</p>
            </div>
            <div className={classes.buttonContainer}>
              <Button color="primary" className={classes.dropzoneButton} onClick={handleClose} data-cy="ExitButton">
                {files.length > 0 ? "Cancel" : "Close"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
                className={classes.dropzoneButton}
                disabled={isUploading || files.length < 1}
                data-cy="SubmitButton"
              >
                Submit
              </Button>
            </div>
          </Card>
        </div>
      ) : (
        ""
      )}
      <Snackbar
        open={isComplete}
        onClose={handleDismissToast}
        TransitionComponent={SlideTransition}
        data-my="SuccessMessage"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SnackbarContent
          classes={{ root: classes.uploadSnackbar }}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleDismissToast}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          message={`${completedFiles} files uploaded`}
        />
      </Snackbar>
    </>
  );
};

export default Dropzone;
