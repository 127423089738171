/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PhonePausedIcon from "@material-ui/icons/PhonePaused";
import { formatTime } from "../../../../utils";
import { Typography } from "@material-ui/core";
import { paletteLight } from "../../../../newTheme/palette";
// import { CallDetails } from "./../../../../models/Call";

const useQualitiesStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  title: {
    fontFamily: "poppins",
    textAlign: "center",
  },
  many: {
    fontSize: "36px",
    fill: paletteLight.error.main,
  },
  some: {
    fontSize: "36px",
    fill: paletteLight.info.main,
  },
  none: {
    fontSize: "36px",
    fill: paletteLight.success.main,
  },
}));

interface IconBoxProps {
  duration: number;
}

const OnHoldBox: React.FunctionComponent<IconBoxProps> = ({ duration }) => {
  const theme = useTheme();
  const classes = useQualitiesStyles(theme);

  return (
    <div className={classes.root}>
      <PhonePausedIcon className={duration > 300 ? classes.many : duration > 0 ? classes.some : classes.none} />
      <Typography variant="h5" className={classes.title}>
        {duration > 0 ? formatTime(duration) : "No Holds"}
      </Typography>
    </div>
  );
};
export { OnHoldBox };
export default OnHoldBox;
