import {
  aveniPink,
  azureBlue,
  black,
  darkBlue,
  lightBlue,
  orcaBlue,
  warning,
  error,
  subzeroBlue,
  success,
  nectarYellow,
  pairPurple,
  white,
  background,
} from "../colors/";

export const palette = {
  white: {
    main: white.main,
    constrastText: black.main,
  },
  black: {
    main: black.main,
    constrastText: white.main,
  },
  primary: {
    main: aveniPink.main,
    light: aveniPink.light,
    dark: aveniPink.dark,
    contrastText: white.main,
  },
  secondary: {
    main: orcaBlue.main,
    contrastText: white.main,
  },
  error: {
    main: error.main,
    contrastText: white.main,
  },
  warning: {
    main: warning.main,
    contrastText: white.main,
  },
  success: {
    main: success.main,
    contrastText: white.main,
  },
  info: {
    main: azureBlue.main,
    contrastText: white.main,
  },
  lightBlue: {
    main: lightBlue.main,
    light: lightBlue.light,
    constrastText: white.main,
  },
  subzeroBlue: {
    main: subzeroBlue.main,
    constrastText: subzeroBlue.main,
  },
  pairPurple: {
    main: pairPurple.main,
    constrastText: white.main,
  },
  nectarYellow: {
    main: nectarYellow.main,
    constrastText: orcaBlue.main,
  },
  darkBlue: {
    main: darkBlue.main,
    light: darkBlue.light,
    constrastText: white.main,
  },
  background: {
    main: background.main,
    light: background.light,
    dark: background.dark,
  },
};
