/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { createStyles, Theme, makeStyles, useTheme } from "@material-ui/core/styles";
import { ListItem, ListItemText } from "@material-ui/core";
import { Circle } from "./Circle";
import { paletteLight } from "./../newTheme/palette";
import { formatCallDuration } from "./../utils";
import { CallSummary } from "./../models/Call";
import { FaUserCheck, FaUserSlash } from "react-icons/fa";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridTemplateColumns: "1fr 2fr 2fr 1fr",
      alignItems: "center",
      minWidth: "400px",
      height: "100%",
    },
    authenticatedIcon: {
      color: paletteLight.background.dark,
      fontSize: "28px",
      flex: "auto",
    },
    authenticated: {
      color: paletteLight.info.main,
      fontSize: "24px",
    },
    listItem: {
      whiteSpace: "nowrap",
    },
    apex: {
      display: "flex",
      [theme.breakpoints.down("lg")]: {
        justifyContent: "flex-end",
      },
    },
    primaryListItemText: {
      whiteSpace: "normal",
      fontWeight: 500,
      color: "black",
      margin: `0px ${theme.spacing(2)}px`,
      overflowWrap: "break-word",
    },
    callDuration: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      justifyContent: "center",
      textAlign: "center",
    },
    product: {
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    many: {
      fontSize: "18px",
      fill: paletteLight.error.main,
      verticalAlign: "middle",
    },
    some: {
      fontSize: "18px",
      fill: paletteLight.info.main,
      verticalAlign: "middle",
    },
    none: {
      fontSize: "18px",
      fill: paletteLight.success.main,
      verticalAlign: "middle",
    },
    riskCircle: {},
  }),
);

export interface CallDetailsProps {
  summary: CallSummary;
}
const CallDetails: React.FC<CallDetailsProps> = ({ summary }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <div className={classes.root}>
        <ListItem className={classes.listItem} disableGutters={true}>
          {summary?.authenticated ? (
            <FaUserCheck className={clsx(classes.authenticatedIcon, classes.authenticated)} />
          ) : (
            <FaUserSlash className={classes.authenticatedIcon} />
          )}
        </ListItem>
        <ListItem className={classes.listItem} disableGutters={true}>
          <ListItemText
            classes={{
              primary: classes.primaryListItemText,
            }}
            color="secondary"
            primary={<>{summary?.uploaded_filename}</>}
          />
        </ListItem>

        <ListItem className={classes.callDuration} disableGutters={true}>
          <ListItemText
            classes={{
              primary: classes.primaryListItemText,
            }}
            primary={<>{formatCallDuration(summary?.duration) || "00 : 00"}</>}
          />
        </ListItem>
        <ListItem className={(classes.listItem, classes.apex)} disableGutters={true}>
          <Circle className={classes.riskCircle} risk={summary?.risk_score} />
        </ListItem>
      </div>
    </>
  );
};

export { CallDetails };
export default CallDetails;
