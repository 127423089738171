import React, { useEffect, useState } from "react";
import { ErrorCatch } from "./components/ErrorCatch";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { CssBaseline, LinearProgress } from "@material-ui/core";
import { Fade } from "@material-ui/core";
import { QueryClient, QueryClientProvider } from "react-query";
import jwt from "jwt-decode";
import "./App.css";
// import { theme } from "./theme";
import { lightTheme } from "./newTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { initGA } from "./components/Tracking";
import { useAuth0 } from "@auth0/auth0-react";
import CallList from "./pages/CallList";
import CallDetail from "./pages/CallDetail";
import Admin from "./pages/Admin";
import Platform from "./components/Platform";
import { UserNotVerified } from "./components/UserNotVerified";
import ErrorPage from "./components/ErrorPage/ErrorPage";

const queryClient = new QueryClient();

const trackingId = process.env.REACT_APP_ANALYTICS_ID;
initGA(trackingId);

const ThrowError = () => {
  throw new Error();
};

const AdminPage = () => {
  return <Platform main={<Admin />} contentClass="none" />;
};

const CallListPage = () => {
  return <Platform main={<CallList />} />;
};

const App: React.FC = () => {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();

  const [userIsVerified, setUserIsVerified] = useState<boolean>(false);
  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) return;
    checkIfUserVerified();
  }, [isAuthenticated]);

  const checkIfUserVerified = async () => {
    const claims = await getIdTokenClaims();
    const token = claims["__raw"];
    const user = jwt(token);
    setUserIsVerified(hasTenancy(user));
  };

  const hasTenancy = (user) => {
    return !!user["https://avenidetect.com/tenantName"];
  };

  useEffect(() => {
    if (error) {
      return;
    }
    if (isLoading || isAuthenticated) return;
    !isAuthenticated && loginWithRedirect();
  }, [isLoading, isAuthenticated, loginWithRedirect, error]);

  if (error) {
    return <ErrorPage error={error} />;
  }
  return (
    <Fade in={isAuthenticated}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <ErrorCatch>
            <ThemeProvider theme={lightTheme}>
              <CssBaseline />
              {isLoading || !isAuthenticated ? (
                <LinearProgress />
              ) : (
                <SnackbarProvider maxSnack={3}>
                  {!userIsVerified ? (
                    <UserNotVerified />
                  ) : (
                    <Switch>
                      <Route path="/call/:id" component={CallDetail}></Route>
                      {/* <Route path="/mireport" component={MIReports}></Route> */}
                      <Route path="/errorpage" component={ThrowError}></Route>
                      <Route path="/admin" component={AdminPage}></Route>
                      <Route exact path="/call">
                        <Redirect to="/" />
                      </Route>
                      <Route exact path="/" component={CallListPage}></Route>
                    </Switch>
                  )}
                </SnackbarProvider>
              )}
            </ThemeProvider>
          </ErrorCatch>
        </Router>
      </QueryClientProvider>
    </Fade>
  );
};

export default App;
