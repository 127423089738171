/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";
import { paletteLight } from "../../../newTheme/palette";
import { useStoreActions, useStoreState } from "./../../../hooks";
import { CallStatus } from "./../../../models";

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",
  },
  appBar: {
    backgroundColor: "transparent",
  },
  tabSelected: {
    backgroundColor: paletteLight.lightBlue.light,
    color: paletteLight.darkBlue.main,
  },
  tabButton: {
    minHeight: "64px",
    minWidth: "120px",
  },
}));

const SimpleTabs: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const callStatus = useStoreState((state) => state.callStatus);
  const setCallStatus = useStoreActions((actions) => actions.setCallStatus);

  const handleChange = (_event: React.ChangeEvent, newValue: CallStatus) => {
    setCallStatus(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.appBar}>
        <Tabs
          value={callStatus}
          onChange={handleChange}
          indicatorColor="primary"
          aria-label="completed and pending tabs"
        >
          <Tab
            classes={{ root: classes.tabButton, selected: classes.tabSelected }}
            label={"pending"}
            {...a11yProps(1)}
            value={"pending"}
            data-cy="PendingTab"
          />
          <Tab
            classes={{ root: classes.tabButton, selected: classes.tabSelected }}
            label={"complete"}
            {...a11yProps(1)}
            value={"complete"}
            data-cy="CompleteTab"
          />
          <Tab
            classes={{ root: classes.tabButton, selected: classes.tabSelected }}
            label={"unassessable"}
            {...a11yProps(2)}
            value={"unassessable"}
            data-cy="UnassessableTab"
          />
        </Tabs>
      </div>
    </div>
  );
};

export default SimpleTabs;
export { SimpleTabs };
