import React, { useEffect } from "react";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  LinearProgress,
  Divider,
  Typography,
  List,
  Grid,
} from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { systemStats, downloadCallList } from "./../../../services/api";
import { ListStat } from "./ListStat";
import { DatePicker } from "./DatePicker";
import groupBy from "../../../utils/groupBy";
import fileDownload from "js-file-download";
import { aveniPink } from "./../../../newTheme/colors";
import { useStoreState, useStoreActions } from "./../../../hooks";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      marginTop: theme.spacing(8),
    },
    indentText: {
      textIndent: "2em",
    },
    downloadButton: {
      color: aveniPink.light,
      backgroundColor: "rgba(252, 228, 237, 0.71)",
      paddingLeft: "48px",
      paddingRight: "48px",
      textTransform: "none",
      maxWidth: "200px",
    },
  });
});

const formatStartDate = (rawStartDate) => {
  const startDate = rawStartDate ? new Date(rawStartDate) : undefined;
  startDate && startDate.setHours(0);
  startDate && startDate.setMinutes(0);
  startDate && startDate.setSeconds(0);
  return startDate;
};

const formatEndDate = (rawEndDate) => {
  const endDate = rawEndDate ? new Date(rawEndDate) : undefined;
  endDate && endDate.setHours(23);
  endDate && endDate.setMinutes(59);
  endDate && endDate.setSeconds(59);
  return endDate;
};

const SystemStats: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { getIdTokenClaims } = useAuth0();
  const selectedStartDate = useStoreState((state) => state.adminStartDate);
  const setSelectedStartDate = useStoreActions((actions) => actions.setAdminStartDate);
  const selectedEndDate = useStoreState((state) => state.adminEndDate);
  const setSelectedEndDate = useStoreActions((actions) => actions.setAdminEndDate);

  const { data, error, isLoading, refetch } = useQuery(
    ["systemStats", { selectedStartDate, selectedEndDate }],
    async () => {
      const claims = await getIdTokenClaims();
      const token = claims["__raw"];
      const startDate = formatStartDate(selectedStartDate ? new Date(selectedStartDate) : undefined);
      const endDate = formatEndDate(selectedEndDate ? new Date(selectedEndDate) : undefined);
      const dta = await systemStats({
        token,
        startDate: startDate ? startDate.toISOString() : undefined,
        endDate: endDate ? endDate.toISOString() : undefined,
      });
      const groupedRiskTypes = groupBy(dta.riskTypes, "risk_sub_category");
      return { ...dta, groupedRiskTypes };
    },
  );

  const handleStartDateChange = (date: Date | null) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setSelectedEndDate(date);
  };

  const handleDownload = async () => {
    const claims = await getIdTokenClaims();
    const token = claims["__raw"];
    const startDate = formatStartDate(selectedStartDate ? new Date(selectedStartDate) : undefined);
    const endDate = formatEndDate(selectedEndDate ? new Date(selectedEndDate) : undefined);
    const { csv } = await downloadCallList({ token, startDate, endDate });
    const blob = new Blob([csv], { type: "text/csv" });

    const startString = startDate ? startDate.toISOString().split("T")[0] : "start";
    const endString = endDate ? endDate.toISOString().split("T")[0] : "end";
    fileDownload(blob, `${startString}to${endString}.csv`);
  };

  useEffect(() => {
    refetch();
  }, [selectedStartDate, selectedEndDate]);

  if (isLoading)
    return (
      <div className={classes.root}>
        <LinearProgress />
      </div>
    );
  if (error) return <div className={classes.root}>{JSON.stringify(error, null, 2)}</div>;

  return (
    <div className={classes.root}>
      <Typography variant="h2">System Stats</Typography>

      <Grid container spacing={2}>
        <Grid item xs={6} md={3} lg={2}>
          <DatePicker
            selectedDate={selectedStartDate}
            handleDateChange={(date: Date | null) => handleStartDateChange(date)}
            label="From"
          />
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <DatePicker
            selectedDate={selectedEndDate}
            handleDateChange={(date: Date | null) => handleEndDateChange(date)}
            label="To"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <List>
            <ListStat primary="Users" secondary="Total number of users" value={data.users} />
            <ListStat primary="Risks" secondary="Total number of risks" value={data.risks} />
            <ListStat primary="Audio" secondary="Total duration of audio" value={`${formatTime(data.duration) || 0}`} />
            <ListStat primary="Audio" secondary="Avg call length" value={`${formatTime(data.avgCall) || 0}`} />
            <ListStat
              primary="Total Calls"
              secondary={
                <>
                  <div>
                    Calls reviewed - <strong>{data.reviewedCalls}</strong>
                  </div>
                </>
              }
              value={data.totalCalls}
            />
            <ListStat
              primary=""
              secondary={
                <>
                  {Object.keys(data.groupedRiskTypes).map((risk) => (
                    <div className={classes.indentText} key={risk}>
                      {`${data.groupedRiskTypes[risk][0].risk} - ${data.groupedRiskTypes[risk][0].risk_sub_category} - ${data.groupedRiskTypes[risk][0].count}`}
                    </div>
                  ))}
                </>
              }
            />
            <Divider />
          </List>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} md={3} lg={2}>
          <Button className={classes.downloadButton} size="large" variant="contained" onClick={handleDownload}>
            Download Call Information
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export { SystemStats };

export const formatTime = (d: number) => {
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
  return hDisplay + mDisplay + sDisplay;
};
