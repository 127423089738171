import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { createStyles, Theme, makeStyles, useTheme } from "@material-ui/core/styles";
import { Toolbar, AppBar, CssBaseline, IconButton, Drawer, useMediaQuery } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import { Filters } from "../../pages/CallList/components/Filters";
import { paletteLight } from "./../../newTheme/palette";
import fulllogoTransparent from "./../../images/fulllogoTransparent.png";
import ProfileButton from "../ProfileButton";
import Dropzone from "../../pages/CallList/components/Dropzone/Dropzone";
import { hasRole } from "../../utils";

const drawerWidth = 64;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      height: "100vh",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      borderBottom: `8px solid ${paletteLight.primary.main}`,
    },
    topToolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    // drawerPaper: {
    //   width: drawerWidth,
    //   borderRight: `1px solid ${paletteLight.secondary.main}`,
    // },
    content: {
      display: "flex",
      width: "100%",
      height: "100%",
      flexDirection: "row",
      paddingTop: theme.spacing(4),
      backgroundColor: paletteLight.subzeroBlue.main,
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%23056fab' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%2307659d' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%230a5a8f' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%230c5081' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%230e4673' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
      background-attachment: fixed;
      background-size: cover;`,
    },
    none: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      paddingTop: theme.spacing(4),
    },
    logo: {
      height: "36px",
      cursor: "pointer",
    },
    container: {
      position: "relative",
      width: "100%",
      margin: theme.spacing(4),
    },
    drawerTab: {
      zIndex: 1,
      transformOrigin: "left top 0",
      backgroundColor: paletteLight.secondary.main,
      padding: theme.spacing(1),
      cursor: "pointer",
      color: paletteLight.white.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    drawerPaper: {
      width: "460px",
    },
    drawerHeader: {
      // backgroundColor:,,
    },
    drawerIcon: {
      color: paletteLight.white.main,
      position: "absolute",
      top: "5px",
      right: "0px",
      fontSize: "20px",
      zIndex: 2,
      marginRight: "2px",
      "&:hover": {
        backgroundColor: paletteLight.white.main,
        color: paletteLight.darkBlue.light,
      },
    },
  }),
);

interface PlatformProps {
  main: React.ReactElement;
  contentClass?: string;
}

const Platform: React.FunctionComponent<PlatformProps> = ({ main, contentClass = "content" }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { user } = useAuth0();
  const largeBreak = useMediaQuery(theme.breakpoints.down("md"));
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.topToolbar}>
          {largeBreak ? (
            <>
              <IconButton className={classes.drawerTab} onClick={() => setDrawerOpen(!isDrawerOpen)}>
                <MenuIcon />
              </IconButton>
              <Drawer
                classes={{ paper: classes.drawerPaper }}
                open={isDrawerOpen}
                anchor="left"
                ModalProps={{ onBackdropClick: () => setDrawerOpen(false) }}
              >
                {/* <div className={classes.drawerHeader}> */}
                <IconButton onClick={() => setDrawerOpen(false)} className={classes.drawerIcon}>
                  <CloseIcon />
                </IconButton>
                {/* </div> */}
                <Filters isDrawerOpen={isDrawerOpen} setDrawerOpen={setDrawerOpen} />
              </Drawer>
            </>
          ) : null}
          <img alt="aveni" src={fulllogoTransparent} className={classes.logo} onClick={() => history.push("/")} />
          <ProfileButton />
        </Toolbar>
      </AppBar>
      <main className={classes[contentClass]} role="main">
        <div className={classes.container}>
          {main}
          {hasRole(user, "Uploader") && <Dropzone />}
        </div>
      </main>
    </div>
  );
};

export default Platform;
