/* eslint-disable react/prop-types */
import React from "react";
import Chart from "react-apexcharts";
import { paletteLight } from "./../newTheme/palette";
export interface ChartProps {
  risk: number;
}

const colors = [
  "transparent",
  paletteLight.info.main,
  paletteLight.subzeroBlue.main,
  paletteLight.pairPurple.main,
  paletteLight.nectarYellow.main,
  paletteLight.primary.main,
];
const Circle: React.FC<ChartProps> = ({ risk = 0 }) => {
  const state = {
    series: [risk * 20],
    options: {
      states: {
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      chart: {
        height: 200,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      colors: [colors[risk]],
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          hollow: {
            margin: 0,
            size: "60%",
            background: "transparent",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
          },
          track: {
            background: "#ccc",
            strokeWidth: "100%",
            margin: 0, // margin is in pixels
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: false,
              color: "#888",
              fontSize: "24px",
            },
            value: {
              offsetY: 7,
              formatter: function (val) {
                return parseInt(val) / 20;
              },
              color: "#111",
              fontSize: "18px",
              fontFamily: "Roboto, sans-serif",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "solid",
        color: "red",
      },
      stroke: {
        lineCap: "round",
      },
      labels: [""],
    },
  };

  return <Chart options={state.options} series={state.series} type="radialBar" width="100" />;
};

export { Circle };
